import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Ellipse from "../../assets/Photos/Ellipse.png";

//
import { ViewProfileicon } from "../../assets/SVGs/SVG";
import { Profilelogout } from "../../assets/SVGs/SVG";
import { Profilesetting } from "../../assets/SVGs/SVG";
import { Manageicon } from "../../assets/SVGs/SVG";
import ReactImageFallback from "react-image-fallback";
import defaultImage from "../../assets/Photos/default_user.png";
//
import { showContext } from "../../context/Form/ShowContext";
import { getAccountDetails, logout } from "../../services/auth/auth";

const Dropdown = () => {
  const { isShown, setIsShown } = useContext(showContext);

  const [user, setUser] = useState(null);
  // console.log({user})

  useEffect(() => {
    getAccountDetails().then((details) => {
      setUser(details);
    });
  }, []);

  let userImage =
    user?.image ||
    `https://ui-avatars.com/api/?name=${user?.name}&background=B21E14&color=fff`;
  return (
    <div>
      {/* Profile drop down */}
      {isShown ? (
        <div className="absolute z-50 bg-white right-8" style={{ top: "57px" }}>
          <div className="">
            <div className="bg-red-600 w-60 h-1"></div>
            <div className="border border-navgray shadow-md  rounded-lg w-full flex  items-center ">
              <div className="pb-4 ">
                <div className="bg-pink-200 w-60">
                  <div className="flex mb-4 px-4">
                    <div className="w-8">
                      <div className="avatar">
                        <div className="mask mask-squircle w-10 h-10">
                          <ReactImageFallback
                            onClick={() => setIsShown(!isShown)}
                            src={userImage}
                            fallbackImage={defaultImage || ""}
                            initialImage={defaultImage || ""}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pt-1.5 pl-1.5">
                      <div className="text-[12px] ml-3">{user?.name}</div>
                      <div className="text-grayon text-[10px] ml-3">
                        {user?.email}
                      </div>
                    </div>
                  </div>
                </div>

                {/* view profile */}
                {/* <div className="flex mb-4 px-4">
                  <div className="w-8">
                    <ViewProfileicon />
                  </div>

                  <div>
                    <div className="text-grayon text-sm ml-3">View Profile</div>
                  </div>
                </div> */}

                {/* Account settings */}
                {/* Account settings */}
                <Link to="/settings" className="flex mb-6 px-4">
                  <div className="">
                    <Profilesetting />
                  </div>

                  <div>
                    <div className="text-grayon text-sm ml-5">
                      Account Settings
                    </div>
                  </div>
                </Link>

                {/* Manage */}
                {/* <div className="flex mb-6 px-4">
                  <div className="">
                    <Manageicon />
                  </div>

                  <div>
                    <div className="text-grayon text-sm ml-5">Manage</div>
                  </div>
                </div> */}

                {/* Logout */}
                <button
                  onClick={() => {
                    logout();
                  }}
                >
                  <div className="flex px-4">
                    <div className="">
                      <Profilelogout />
                    </div>
                    <div>
                      <div className="text-grayon text-sm ml-5">Logout</div>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Dropdown;
