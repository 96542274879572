import { createEmailSession, getAccount, deleteSession, doctorAccountDetails, adminAccountDetails } from "../../utils/appwrite/appwriteConfig"



export const login = (email, password) => {
  return createEmailSession(email, password).then((res) => {
    localStorage.setItem("doci_admin_user", JSON.stringify(res));
    return res;
  });
};



  export const logout = () => {
    return deleteSession().then((res) => {
      localStorage.removeItem("doci_admin_user");
      return res;
    });
  };

  export const getAccountDetails = () => {
    return getAccount().then((res) => {
      return res;
    });
  };



  export const getAdminAccountDetails = (userID) => {
    return adminAccountDetails(userID).then((res) => {
      return res;
    });
  };
 
  

export const getDoctorAccountDetails = (userID) => {
  return doctorAccountDetails(userID).then((res) => {
    return res;
  });
};

