import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { IoReturnDownBackOutline } from "react-icons/io5";

//
import Frame from "../../assets/Photos/Frame.png";

//
import { DataContext } from "../../context/Data/DataContext";
import { generalContext } from "../../context/Form/GeneralContext";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useOrganizations from "../../hooks/useOrganizations.jsx";
import { createOrganization } from "../../utils/appwrite/appwriteConfig";
import useNotification from "../../../src/hooks/useNotifications";
import { UtilityContext } from "../../Utility/UtilityContext";

//
import FormInput from "./Input";

const Orgform = (props) => {
  const navigate = useNavigate();
  

  const { createOrganizationAccount } = useOrganizations();
  // const { setShowLoader } = useContext(UtilityContext);
  const { handleError, handleSuccess } = useNotification();

  const validationSchema = Yup.object().shape({
    company_name: Yup.string().required("Enter company's name"),
    company_address: Yup.string().required("Enter company's address"),
    company_email: Yup.string().required("Enter company's email"),
    rc_number: Yup.string().required("Enter company's RC number"),
    first_name: Yup.string().required("Enter your First Name"),
    last_name: Yup.string().required("Enter your Last Name"),
    admin_email: Yup.string().required("Enter your email address"),
    admin_password: Yup.string().required("Enter your password"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const submitHandler = async (data) => {
    try {
      console.log(data);
      createOrganizationAccount(data).then((res) => {
        console.log(res);
        reset();
        navigate("/organizations");
      });
    } catch (error) {
      console.log(error.message);

      return;
    }
  };
  const { active, setActive } = useContext(generalContext);

  const { orgInformation, setOrgInformation } = useContext(DataContext);

  const {
    company_name,
    company_address,
    company_email,
    admin_email,
    admin_password,
    rcnumber,
    submit,
  } = orgInformation;

  const changeHandler = (e) => {
    setOrgInformation({
      ...orgInformation,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="">
      {active ? (
        <div className=" flex justify-center items-center pt-24 px-12 md:px-40 bg-pink-100/30 ">
          {/* <Popup className="max-w-xl" open={props.doctorForm}  closeOnDocumentClick onClose={()=>props.setDoctorForm(false)} position="right center"></Popup> */}
          <div className="border-2  w-full h-full px-4 md:px-8 py-12 rounded-md shadow-xl relative z-50 ">
            {/* back */}
            <div>
              <Link to="/organizations">
                <IoReturnDownBackOutline size={25} />
              </Link>
            </div>

            {/* logo */}
            <div className="">
              <img src={Frame} alt="" className="w-1/2 lg:w-1/5" />
            </div>
            {/* introduction */}
            <div className="flex justify-between font-medium pb-4">
              <div className="text-start">Fill in Company's information</div>
              <div className="text-end">required</div>
            </div>

            <form onSubmit={handleSubmit(submitHandler)}>
              <div className=" md:grid grid-cols-2  ">
                <div className=" pb-12 md:pr-8">
                  <label
                    htmlFor="first-name"
                    className="block text-sm  font-medium  text-consultblack"
                  >
                    Company's Name
                  </label>

                  <FormInput
                    name={"company_name"}
                    type="text"
                    placeholder="Lhezie"
                    required="yes"
                    register={register}
                    size=""
                  />
                </div>

                {/* organization address*/}
                <div className=" md: lg:pr-0 pb-6 md:pl-8">
                  <label
                    htmlFor="age"
                    className="block text-sm  font-medium  text-consultblack"
                  >
                    Company's Address
                  </label>

                  <FormInput
                    name={"company_address"}
                    type="text"
                    placeholder="Address"
                    required="yes"
                    register={register}
                    size=""
                  />
                </div>

                {/* company email*/}
                <div className=" pb-12 md:pr-8">
                  <label
                    htmlFor="first-name"
                    className="block text-sm  font-medium  text-consultblack"
                  >
                    Company's Email
                  </label>

                  <FormInput
                    name={"company_email"}
                    type="email"
                    placeholder="Company's Email"
                    required="yes"
                    register={register}
                    size=""
                  />
                </div>

                {/* company rc_number */}
                <div className="">
                  <div className="  md: lg:pr-0 pb-8 md:pl-8 ">
                    <label
                      htmlFor="DOB"
                      className="block text-sm  font-medium  text-consultblack"
                    >
                      RC Number
                    </label>
                    <FormInput
                      name={"rc_number"}
                      type="text"
                      placeholder=" RC Number"
                      required="yes"
                      register={register}
                      size=""
                    />
                  </div>
                </div>

                {/* company admin firstname*/}
                <div className=" pb-12 md:pr-8">
                  <label
                    htmlFor="first-name"
                    className="block text-sm  font-medium  text-consultblack"
                  >
                    Admin's First Name
                  </label>

                  <FormInput
                    name={"first_name"}
                    type="text"
                    placeholder="Admin's Name"
                    required="yes"
                    register={register}
                    size=""
                  />
                </div>

                {/* company admin lastname */}
                <div className="">
                  <div className="  md: lg:pr-0 pb-8 md:pl-8 ">
                    <label
                      htmlFor="last name"
                      className="block text-sm  font-medium  text-consultblack"
                    >
                      Admin's Last Name
                    </label>
                    <FormInput
                      name={"last_name"}
                      type="text"
                      placeholder="Last Name"
                      required="yes"
                      register={register}
                      size="w-full "
                    />
                  </div>
                </div>

                {/* company admin email*/}
                <div className=" pb-12 md:pr-8">
                  <label
                    htmlFor="first-name"
                    className="block text-sm  font-medium  text-consultblack"
                  >
                    Company Admin's Email
                  </label>

                  <FormInput
                    name={"admin_email"}
                    type="email"
                    placeholder="Admin's Email"
                    required="yes"
                    register={register}
                    size=""
                  />
                </div>

                {/* company admin password */}
                <div className="">
                  <div className="  md: lg:pr-0 pb-8 md:pl-8 ">
                    <label
                      htmlFor="DOB"
                      className="block text-sm  font-medium  text-consultblack"
                    >
                      Company Admin's Password
                    </label>
                    <FormInput
                      name={"admin_password"}
                      type="password"
                      placeholder="***********"
                      required="yes"
                      register={register}
                      size="w-full "
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-center items-center cursor-pointer">
                <button
                  type="submit"
                  className="bg-pink-200 italic px-12 py-4 rounded-md shadow-sm"
                >
                  Register Company
                </button>
              </div>
            </form>
          </div>
          {/* <Popup/> */}
          {/* </div> */}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Orgform;
