import React, { useContext, useState, useEffect } from "react";
import Layout from "../component/Layout Component/Layout";
import { Link, useNavigate } from "react-router-dom";
import defaultImage from "../assets/Photos/default_user.png";
import useNotification from "../hooks/useNotifications";
import InputForm from "../component/Form Component/InputForm";
import { DataContext } from "../context/Data/DataContext";
import useDoctors from "../hooks/useDoctors";
import useAdmins from "../hooks/useAdmins";
import { useUser } from "../hooks/useUser";
import ReactImageFallback from "react-image-fallback";
import { getAccountDetails } from "../services/auth/auth";

import { UserContext } from "../context/User/UserContext";
import { showContext } from "../context/Form/ShowContext";

import { Client, Account, Databases, Query, ID } from "appwrite";

const client = new Client();

client
  .setEndpoint(process.env.REACT_APP_API)
  .setProject(process.env.REACT_APP_PROJECT_KEY);
const DBID = process.env.REACT_APP_APPWRITE_DATABASE_ID;
const AdminsCollection = "6393175fb6f0cd0aa8d2";
const account = new Account(client);

const DB = new Databases(client);


// get admin by id
async function getAdminsbyId(id) {
  try {
    console.log(`userID`, id);
    const data = await DB.listDocuments(DBID, AdminsCollection, [
      Query.equal("userID", id),
    ]);
    return data?.documents[0];
  } catch (error) {
    console.log(error);
    return null;
  }
}

const Settings = () => {
  const {
    allDoctors,
    setAllDoctors,
    doctors,
    setDoctors,
    updateDoctor,
    getAllDoctorsList,
  } = useDoctors();
  const { admins, setAdmins, getAllAdminsList, allAdmins, setAllAdmins } =
    useAdmins();

  const { doctorsInfo, setDoctorsInfo, doctor, setDoctor } =
    useContext(DataContext);
  const navigate = useNavigate();
  const { handleError, handleSuccess } = useNotification();
  const [dataSaved, setDataSaved] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const { doctorDetails, userDetails, setUserDetails, setDoctorDetails } =
    useContext(UserContext);
  const { isShown, setIsShown } = useContext(showContext);

  const [updatedSettings, setUpdatedSettings] = useState({});
  const [user, setUser] = useState({});
  console.log({user})

  useEffect(() => {
    console.log("admin details is a=> ", user.$id);
    getAccountDetails().then((details) => {
      setUser(details);
    });
  }, []);

  // get user account details
async function getAccountDetails() {
  try {
    const data = await account.get();
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

 
useEffect(() => {
  async function fetchData() {
    const accountDetails = await getAccountDetails();
    setUser(accountDetails);

    if (accountDetails && accountDetails.isAdmin) {
      const adminDetails = await getAdminsbyId(accountDetails.id);
      setAdmins(adminDetails);
    }
  }

  fetchData();
}, []);
 




  const handleSubmit = async (e) => {
    console.log({ event: e });
    e.preventDefault();

    if (user?.$id) {
      // Update data
      updateData(updatedSettings);
    }
  };

  const updateData = async (data) => {
    try {
      console.log(`doctor => `, data);
      const adminId = user?.$id;
      console.log(
        "update doctor that has id => ",
        adminId,
        " with data => ",
        data
      );
      setLoading(true);
      const response = await DB.updateDocument(
        DBID,
        AdminsCollection,
        adminId,
        {
          ...data,
          $collectionId: undefined,
          $createdAt: undefined,
          $databaseId: undefined,
          $id: undefined,
          $permissions: undefined,
          $updatdAt: undefined,
          $passwordUpdate: undefined,
          $emailVerification: undefined,
          $prefs: undefined,
          $status: undefined,
          $registrations: undefined,
        }
      );
      console.log("Data updated:", response);
      if (response) {
        handleSuccess("Updated sucessfully");
      } else {
        handleError("an error occured");
      }
      // setUpdated(true)
    } catch (error) {
      console.log({ error });
      handleError(error);
      return false;
    } finally {
      // setLoading(false);
    }
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setUpdatedSettings((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  let userImage =
  user?.image ||
  `https://ui-avatars.com/api/?name=${
    user?.name
  }&background=B21E14&color=fff`;

  return (
    <Layout>
      <form onSubmit={handleSubmit}>
        <div className="md:py-12">
          <div className="text-center text-gray-500 text-5xl">Edit Profile</div>
          <div className=" h-full bg-base-100 shadow-xl md:grid grid-cols-3 ">
          <figure className="   px-10 md:pt-20  flex justify-center  md:flex justify-start">
              <div className="avatar">
                <div className="mask mask-squircle w-full h-1/2 shadow-2xl">
                  <ReactImageFallback
                    onClick={() => setIsShown(!isShown)}
                    src={userImage}
                    fallbackImage={defaultImage}
                    initialImage={defaultImage}
                    alt=""
                  />
                </div>
              </div>

            </figure>

            <div className="md:pt-12 md:pr-4  px-24 md:px-0 text-center md:text-start">
              <div className="">
                <label className="block text-sm  font-medium  text-consultblack ">
                  Name
                </label>
                <input
                  name="first_name"
                  type="text"
                  defaultValue={user?.name}
                  onChange={changeHandler}
                  className={
                    `w-full  ` +
                    ` mt-1 mb-six block h-12 rounded-md text-left pl-2 text-sm sm:text-sm  bg-white border drop-shadow-sm border-slate-300 placeholder-nextgray placeholder-center md:placeholder-start focus:outline-none focus:border-consultbg focus:ring-consultbg`
                  }
                />
              </div>
            </div>

            <div className="md:pt-12 md:pr-4  px-24 md:px-0 text-center md:text-start">
              <div className="">
                <label className="block text-sm  font-medium  text-consultblack ">
                  Email
                </label>
                <input
                  name="name"
                  type="text"
                  defaultValue={user?.email}
                  onChange={changeHandler}
                  className={
                    `w-full  ` +
                    ` mt-1 mb-six block h-12 rounded-md text-left pl-2 text-sm sm:text-sm  bg-white border drop-shadow-sm border-slate-300 placeholder-nextgray placeholder-center md:placeholder-start focus:outline-none focus:border-consultbg focus:ring-consultbg`
                  }
                />
              </div>
            </div>

            <div></div> 
            <div></div> 
            
            <div className="flex justify-end pr-12 pb-32 pt-12">
            <button
              type="submit"
              // onClick={() => console.log("clicked")}
              className="p-2 px-6 text-sm h-8 md:h-10 border flex justify-end border-red-500 text-nextgray bg-white hover:bg-addred  hover:text-white rounded-md"
            >
              Update
            </button>
            </div>
            
            <div></div> 
            <div></div>
            <div></div> 
            <div></div>
            <div></div> 
            <div></div>
            <div></div> 
            <div></div>
            <br />
            <br />
            <br />
          </div>
          
        </div>
      </form>
    </Layout>
  );
};

export default Settings;