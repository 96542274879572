import React, { useEffect, useContext, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { BiSearchAlt } from "react-icons/bi";

import { Link } from "react-router-dom";
import useHealthtips from "../../hooks/useHealthtips";
import { generalContext } from "../../context/Form/GeneralContext";

function truncateText(text, quantity) {
  // Check if the text is longer than the quantity
  if (text.length > quantity) {
    // If it is, truncate the text and add "..."
    return text.substring(0, quantity) + "...";
  }
  // If the text is not longer than the quantity, just return the original text
  return text;
}

const Healthtiptable = () => {
  const { active, setActive } = useContext(generalContext);
  const [products, setProducts] = useState([]);
  const { getAllHealhtipsList, allHealthtips, updateHealthtips } =
    useHealthtips();
  console.log({ allHealthtips });

  const [searchResult, setSearchResult] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    const filteredHealthtips = allHealthtips.filter(
      (healthtips) =>
        healthtips.title.toLowerCase().includes(value.toLowerCase()) ||
        healthtips.date.toLowerCase().includes(value.toLowerCase()) ||
        healthtips.tags.toLowerCase().includes(value.toLowerCase())
    );
    setSearchResult(filteredHealthtips);
  };

  useEffect(() => {
    console.log("all patients are => ", allHealthtips);
    setSearchResult(allHealthtips);
  }, [allHealthtips]);

  useEffect(() => {
    getAllHealhtipsList();
  }, []);

  return (
    <>
      {/* search */}
      <div className="flex justify-end px-8 md:px-2 mb-5">
        <label class="relative block mt-6 md:mt-8 w-1/2 md:w-1/2 lg:w-1/5">
          <span class="sr-only ">Search</span>
          <div className="flex">
            <div class="absolute inset-y-0 left-0 pt-3 pl-2 text-searchicon z-10">
              <BiSearchAlt className="" />
            </div>
            <input
              class=" placeholder:text-searchicon block bg-white w-full border border-slate-300 rounded-md py-2 pl-6 pr-2 shadow-sm   drop-shadow-sm placeholder-nextgray focus:outline-none focus:border-consultbg focus:ring-consultbg focus:ring-1 sm:text-sm"
              placeholder="Search"
              type="text"
              name="search"
              value={searchValue}
              onChange={handleSearchChange}
            />
          </div>
        </label>
      </div>
      <div className=" px-8 md:px-2">
        <div className="w-full h-fit border border-navgray">
          <div className="overflow-x-auto w-full">
            <table className="table w-full">
              {/* <!-- head --> */}
              <thead>
                <tr className="bg-pink-200">
                  <th className="bg-pink-200">
                    <div className="bg-pink-200">#</div>
                  </th>
                  <th className="bg-pink-200">Title</th>
                  <th className="bg-pink-200 ">Image</th>
                  <th className="bg-pink-200 ">Date</th>
                  <th className="bg-pink-200">Description</th>
                  <th className="bg-pink-200">Tags</th>
                  <th className="bg-pink-200"></th>
                </tr>
              </thead>
              <tbody>
                {/* <!-- row 1 --> */}
                {searchResult?.map((elem, index) => (
                  <tr>
                    <td>
                      <label>
                        <div>{index + 1}</div>
                      </label>
                    </td>
                    <td>
                      <div className="flex items-center space-x-3">
                        <div>
                          <div className="font-bold text-center">
                            {truncateText(elem?.title, 20)}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="btn btn-ghost btn-xs">
                        {truncateText(elem?.image, 20)}
                      </div>
                    </td>
                    <td className="">
                      {new Date(elem?.date).toLocaleTimeString()}
                    </td>
                    <td className="">{truncateText(elem?.description, 20)}</td>
                    <td className="">{elem?.tags}</td>
                    <td className="">
                      <Link to={`/edithealthtips?id=${elem?.$id} `}>
                        <BiEdit size={20} />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Healthtiptable;
