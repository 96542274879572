import React, {useContext, useEffect, useState} from 'react'
import Layout from "../component/Layout Component/Layout"
import { Link, useNavigate } from 'react-router-dom';
import { Orgcard } from '../component/Layout Component/Cards';
import Orginfotable from '../component/Layout Component/Orginfotable';


//
import { BiSearchAlt } from "react-icons/bi";
import { FaRegCopy } from "react-icons/fa";
import { AiOutlineFolderView } from "react-icons/ai"
import useNotification from "../hooks/useNotifications";
import { BiEdit } from "react-icons/bi";
import { TiDeleteOutline } from "react-icons/ti";

//
import {generalContext } from "../context/Form/GeneralContext"
import useOrganizations from '../hooks/useOrganizations';




const address = process.env.REACT_APP_ORG_URL 


const Adminorg = (props) => {
  const [user, setUser] = useState("")
  const { handleError, handleSuccess } = useNotification();
  const { active, setActive } = useContext(generalContext);
  const id = extractIdFromUrl(window.location.href);
  const { getAllOrganizationsList, allOrganizations } = useOrganizations();


  const [searchResult, setSearchResult] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    const filteredOrganizations = allOrganizations.filter(
      (organization) =>
        organization.company_name.toLowerCase().includes(value.toLowerCase()) ||
        organization.company_email.toLowerCase().includes(value.toLowerCase()) 
        // organization.email.toLowerCase().includes(value.toLowerCase())
    );
    setSearchResult(filteredOrganizations);
  };
  

  useEffect(() => {
    console.log("all patients are => ", allOrganizations);
    setSearchResult(allOrganizations);
  }, [allOrganizations]);

  function extractIdFromUrl(url) {
    const parts = url.split('/');
    return parts[parts.length - 3];
  }


  const [copied, setCopied] = useState(false);


    // this copies the link of organizations
  const handleCopy = (id, company_name, company_registration_link) => {
    // const localaddress = "http://localhost:3002"
    // const productionAddress = "https://doci-org-dashboard.vercel.app/"
    // const company_name = allOrganizations.company_name
    navigator.clipboard.writeText(`${company_registration_link.replace(/\s+/g, "-")}`);
    setCopied(true);
    handleSuccess("Copied Successfully");
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };


  const handleCopyy = (id, company_name, company_registration_link) => {
    const localaddress = "http://localhost:3002"
    const productionAddress = address
    navigator.clipboard.writeText(`${window.location.hostname==="localhost"?localaddress:productionAddress}/org/${company_name.replace(/\s+/g, "-")}-${id}`);
    setCopied(true);
    handleSuccess("Copied Successfully");
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  // navigator.clipboard.writeText(`${location?.origin}/patient/${user?.org?.company_name?.replace(/\s+/g, "-")}-${user?.orgID}`);
   
  const navigate = useNavigate();

 useEffect(() => {
  getAllOrganizationsList();

 }, []);
  
  
 useEffect(() => {
  console.log("all patients are => ", allOrganizations);
  setSearchResult(allOrganizations);
}, [allOrganizations]);

  return (
   <Layout>
      <Orgcard/>
       {/* search */}
       <div className="flex justify-end px-8 md:px-2 mb-5">
            <label class="relative block mt-6 md:mt-8 w-1/2 md:w-1/2 lg:w-1/5">
              <span class="sr-only ">Search</span>
              <div className="flex">
                <div class="absolute inset-y-0 left-0 pt-3 pl-2 text-searchicon z-10">
                  <BiSearchAlt className="" />
                </div>
                <input

                  class=" placeholder:text-searchicon block bg-white w-full border border-slate-300 rounded-md py-2 pl-6 pr-2 shadow-sm   drop-shadow-sm placeholder-nextgray focus:outline-none focus:border-consultbg focus:ring-consultbg focus:ring-1 sm:text-sm"
                  placeholder="Search"
                  type="text"
              name="search"
              value={searchValue}
              onChange={handleSearchChange}
                />
              </div>
            </label>
                
            </div>
         {/*  */}
         <div className=" px-8 md:px-2">
      <div className="w-full h-fit border border-navgray">
        <div className="overflow-x-auto w-full">
          <table className="table w-full">
            {/* <!-- head --> */}
            <thead>
              <tr className="bg-pink-200">
                <th className="bg-pink-200">
                  <div className="bg-pink-200">#</div>
                </th>
                <th className="bg-pink-200">Organization Names</th>
                <th className="bg-pink-200 ">Email</th>
                <th className="bg-pink-200 text-start">Address</th>
                <th className="bg-pink-200 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {/* <!-- row 1 --> */}
              {searchResult?.map((elem, index) => (
              <tr>
                <td>
                  <label>
                    <div>{index +1}</div>
                  </label>
                </td>
                <td>
                  <div className="flex items-center space-x-3">
                    <div>
                      <div className="font-bold text-center">
                        {elem?.company_name}
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="btn btn-ghost btn-xs">
                    {" "}
                    {elem?.company_email}
                  </div>
                </td>
                <td className=" text-start">{elem?.company_address}</td>
                <td className="flex justify-around items-center text-center mt-4 cursor-pointer">
                <Link to= {`/vieworganization?id=${elem?.$id}`}>
                <AiOutlineFolderView size={25} className="" />
                </Link>
                  <FaRegCopy size={20}  onClick={()=>handleCopyy(elem.$id, elem.company_name, elem.company_registration_link)} />
                  <br />
                </td>
              </tr>

               ))} 
            </tbody>
          </table>
        </div>
      </div>
    </div>     

   </Layout>
  )
}

export default Adminorg