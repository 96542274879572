import {getAllSubscriptions, updateSubscription } from "../../utils/appwrite/appwriteConfig";

export const getSubscriptions = () => {
  return getAllSubscriptions().then((res) => {
    return res;
  });
};
export const updateSubscriptionData = (id,data) => {
  return updateSubscription(id,data).then((res) => {
    return res;
  });
};



