import React, { useState, useContext } from "react";
import Frame from "../../assets/Photos/Frame.png";
import Logo from "../../assets/Photos/logo.png";

import Ellipse from "../../assets/Photos/Ellipse.png";

//
import { BiSearchAlt, BiArrowBack } from "react-icons/bi";
import { IoMdNotificationsOutline } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";
import ReactImageFallback from "react-image-fallback";
import defaultImage from "../../assets/Photos/default_user.png";

//
import Dropdown from "./Dropdown";

//
import { showContext } from "../../context/Form/ShowContext";
import { viewSearchContext } from "../../context/Form/ViewSearchContext";
import useAuth from "../../hooks/useAuth";
import { useEffect } from "react";
import { getAccountDetails } from "../../services/auth/auth";

const Header = () => {
  const { isShown, setIsShown } = useContext(showContext);
  // const { activesearch, setActiveSearch } = useContext(viewSearchContext);
  // const {user}=useAuth()
  const [user, setUser] = useState(null);
  // console.log({user})

  useEffect(() => {
    getAccountDetails().then((details) => {
      setUser(details);
    });
  }, []);

  let userImage =
    user?.image ||
    `https://ui-avatars.com/api/?name=${user?.name}&background=B21E14&color=fff`;

  return (
    <div className="" >
      <div className=" header-container sticky">
        <div className="flex justify-between items-center pl-8 bg-navbg drop-shadow-sm ">
          <div className="flex items-center">
            <label
              htmlFor="my-drawer"
              className="drawer-button flex-1 lg:hidden"
            >
              <GiHamburgerMenu className="text-lg cursor-pointer" />
            </label>
            {/* logo */}
            <div className="">
              <img
                src={Frame}
                alt=""
                srcset=""
                className="max-w-xs hidden md:block"
              />
              <img
                src={Logo}
                alt=""
                srcset=""
                className=" md:hidden"
                style={{ width: "70px" }}
              />
            </div>
          </div>

          {/* small screen search */}
          {/* {activesearch ? (
          <div className="absolute z-100 bg-white w-full  border block md:hidden ">
            <label class=" w-full   ">
              <span className="sr-only ">Search</span>
              
              <div className="flex">
                <div onClick={()=>setActiveSearch(false)} class="absolute inset-y-0 left-0 pt-3 pl-2 text-searchicon z-10">
                  <BiArrowBack size={20} className="cursor-pointer" />
                </div>
                <input
                  className="px-4 placeholder:text-searchicon block bg-white w-full border border-slate-300 rounded-md py-2 pl-6 pr-2 shadow-sm   drop-shadow-sm placeholder-nextgray focus:outline-none focus:border-consultbg focus:ring-consultbg focus:ring-1 sm:text-sm"
                  placeholder="Search"
                  type="text"
                  name="search"
                />
              </div>
            </label>
          </div>) : "" */}
          {/* } */}

          {/* Large screen search */}
          {/* <label class="relative  hidden md:block w-1/4">
            <span class="sr-only ">Search</span>
            <div className="flex">
              <div class="absolute inset-y-0 left-0 pt-3 pl-2 text-searchicon z-10">
                <BiSearchAlt className="" />
              </div>
              <input
                class=" placeholder:text-searchicon block bg-white w-full border border-slate-300 rounded-md py-2 pl-6 pr-2 shadow-sm   drop-shadow-sm placeholder-nextgray focus:outline-none focus:border-consultbg focus:ring-consultbg focus:ring-1 sm:text-sm"
                placeholder="Search"
                type="text"
                name="search"
              />
            </div>
          </label> */}

          <div className="flex justify-center relative-none md:relative  z-12">
            <div className="flex mr-12 items-center justify-center ">
              {/* search icon */}
              {/* <div  onClick={()=>setActiveSearch(true)} className="inline mx-3 md:hidden">
                <BiSearchAlt className="" />
              </div> */}

              {/* notification */}
              <div className="inline pr-2">
                <IoMdNotificationsOutline size={28} />
              </div>
              <div className="inline w-12 border-none hover:border-blue-600">
                {/* <img
                  onClick={() => setIsShown(!isShown)}
                  src={Ellipse}
                  alt=""
                /> */}
                <div className="avatar">
                  <div className="mask mask-squircle w-10 h-10">
                    <ReactImageFallback
                      onClick={() => setIsShown(!isShown)}
                      src={userImage}
                      fallbackImage={defaultImage}
                      initialImage={defaultImage}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div className="text-[10px] hidden md:block">
                <div className="text-sidetextbg">ADMIN</div>
                <div className="text-grayon">{user?.name}</div>
              </div>
            </div>

            {/* Profile drop down */}
            <Dropdown />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
