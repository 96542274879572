import {
  createOrgUserAccount,
  createOrganization,
  createOrgUser,
  updateOrganization
} from "../../utils/appwrite/appwriteConfig";

export const createNewOrgUserAccount = ({
  email,
  first_name,
  last_name,
  password
}
) => {
  const name = `${first_name} ${last_name}`;
  console.log('This is the', email, name, password)
  return createOrgUserAccount({ email, name, password });
};

export const createNewOrganization = (
  userID,
  company_name,
  company_address,
  company_email,
  rc_number
  
) => {
  return createOrganization(
    userID,
    company_name,
    company_address,
    company_email,
    rc_number
  );
};

export const createOrganizationUser = ({ userID, OrgUserID }) => {
  return createOrgUser({ userID, OrgUserID });
};


export const updateOrganizationData = (id, data) => {
  return updateOrganization().then(id, data).then((res) => {
    return res;
  });
};