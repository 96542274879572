import {useState, useContext } from "react";
import { UtilityContext } from "../context/Utility/UtilityContext";
import {  getSubscriptions, updateSubscriptionData } from "../services/subsriptions/subscription";
import useNotification from "../hooks/useNotifications";


const useSubcription= () => {
  const { setShowLoader } = useContext(UtilityContext);
  const { handleError, handleSuccess } = useNotification();
  const [allSubscriptions,setAllSubscriptions] = useState([])

  const updateSubcription = async (id, data) => {
    setShowLoader(true);
    console.log('udpate in useSubscription',id,data);
    try {
       const updateRes = await updateSubscriptionData(id, data)
       console.log({updateRes})
       if(!updateRes.$id) throw new Error(`subscription failed to be updated`)
       handleSuccess("Subscription updated successfully");
       return true
    } catch (error) {
      console.log({error})
      handleError(error);
      return false
    } finally {
      setShowLoader(false);
    }
  };

  const getAllSubscriptionsList = async ()=>{
    const subscriptions = await getSubscriptions()
    console.log({subscriptions})
    setAllSubscriptions(subscriptions.documents)
  }

  return {
    allSubscriptions,
    updateSubcription,
    getAllSubscriptionsList
  };
};

export default useSubcription;
