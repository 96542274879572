import React, { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

//
import Doctorform from "../../component/Form Component/Doctorform";
import EditSubscriptionpage from "../../component/Form Component/EditSubscriptionpage";
import Orgform from "../../component/Form Component/Orgform";
import ViewDoctorpage from "../../component/Form Component/ViewDoctorpage";
import ViewOrganizationpage from "../../component/Form Component/ViewOrganizationpage";
import ViewPatientpage from "../../component/Form Component/ViewPatientpage";
import ViewSubscriptionpage from "../../component/Form Component/ViewSubscriptionpage";
import AdminConsult from "../../pages/AdminConsult";
import AdminDoctors from "../../pages/AdminDoctors";
import AdminOrg from "../../pages/AdminOrg";
import AdminPatients from "../../pages/AdminPatients";
import Healthtips from "../../pages/Healthtips";
import Login from "../../pages/Login";
import Schedular from "../../pages/Schedular";
import Settings from "../../pages/Settings";
import Subplan from "../../pages/Subplan";
import Subscription from "../../pages/Subscription";
// import viewConsultation from "./component/Form Component/ViewConsultation"

//
//
import GeneralProvider from "../../context/Form/GeneralContext";
import ShowProvider from "../../context/Form/ShowContext";
import ViewSearchProvider from "../../context/Form/ViewSearchContext";

import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import EditHealthtipspage from "../../component/Form Component/Edithealthtipspage";
import ViewConsultation from "../../component/Form Component/ViewConsultation";
import { UserContext } from "../../context/User/UserContext";
import useUser from "../../hooks/useUser.jsx";

function LoggedInContainer() {
  // const token = localStorage.getItem("doci_admin_user");
  // const { showLoader } = useContext(UtilityContext);
  const { getuserDetails, logOutUser } = useUser();
  const { adminDetails } = useContext(UserContext);

  useEffect(() => {
    getuserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!adminDetails) logOutUser();
  if (!adminDetails) return <h1>Opps!, sorry not a Admin</h1>;

  return (
    <>
      <MantineProvider>
        {/* <LoadingOverlay visible={showLoader} /> */}

        <ShowProvider>
          <GeneralProvider>
            <ViewSearchProvider>
              <NotificationsProvider>
                <Routes>
                  {/* <Route path='/' element={<AdminPatients/>} /> */}
                  <Route path='/' element={<Login />} />
                  <Route path='/doctors' element={<AdminDoctors />} />
                  <Route path='/consultation' element={<AdminConsult />} />
                  <Route path='/organizations' element={<AdminOrg />} />
                  <Route path='/patients' element={<AdminPatients />} />
                  <Route path='/scheduler' element={<Schedular />} />
                  <Route path='/subscription' element={<Subscription />} />
                  <Route path='/settings' element={<Settings />} />
                  <Route path='/doctorform' element={<Doctorform />} />
                  <Route path='/orgform' element={<Orgform />} />
                  <Route path='/subplan' element={<Subplan />} />
                  <Route path='/viewpatient' element={<ViewPatientpage />} />
                  <Route path='/viewdoctor' element={<ViewDoctorpage />} />
                  <Route path='/healthtip' element={<Healthtips />} />
                  <Route
                    path='/vieworganization'
                    element={<ViewOrganizationpage />}
                  />
                  <Route
                    path='/editsubscription'
                    element={<EditSubscriptionpage />}
                  />
                  <Route
                    path='/edithealthtips'
                    element={<EditHealthtipspage />}
                  />
                  <Route
                    path='/viewconsultation'
                    element={<ViewConsultation />}
                  />
                  <Route
                    path='/viewsubscriptionuser'
                    element={<ViewSubscriptionpage />}
                  />
                  {/* <Route path='/' element={<Login />} /> */}
                </Routes>
              </NotificationsProvider>
            </ViewSearchProvider>
          </GeneralProvider>
        </ShowProvider>
      </MantineProvider>
    </>
  );
}

export default LoggedInContainer;
