import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { generalContext } from "../../context/Form/GeneralContext";
import { IoReturnDownBackOutline } from "react-icons/io5";
import Frame from "../../assets/Photos/Frame.png";
import Logo from "../../assets/Photos/logo.png";

import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useSubscriptionusers from "../../hooks/useSubscriptionusers";
import useSubcriptionuser from "../../hooks/useSubscriptionusers";

const ViewSubscriptionpage = () => {
  const [viewedSubscriptionuser, setViewedSubscriptionuser] = useState(null);
  const { allSubscriptionsuser, updateSubcriptionuser, getAllSubscriptionsuserList} = useSubcriptionuser();

  // console.log({subs:allSubscriptions})

  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("id");

  // console.log({id})

  const subscriptionuser = allSubscriptionsuser.find((plan) => plan.$id == id);
  // console.log({subplan})

  useEffect(() => {
    getAllSubscriptionsuserList();
  }, []);

  const validationSchema = Yup.object().shape({
    company_name: Yup.string().required(""),
    company_address: Yup.string().required(""),
    rc_number: Yup.string().required(""),
    company_email: Yup.string().required(""),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const submitHandler = async (data) => {
    try {
      console.log({ submitted: data });
      updateSubcriptionuser(id, data).then((res) => {
        console.log(res);
        reset();
        navigate("/subscription");
      });
    } catch (error) {
      console.log(error.message);

      return;
    }
  };
  

  const changeHandler = (e) => {
    setViewedSubscriptionuser({
      ...viewedSubscriptionuser,
      [e.target.name]: e.target.value,
    });
  };


  return (
    <div className="bg-pink-100/30 h-screen">
      <div className=" flex justify-center items-center pt-24 px-12 md:px-40  ">
        <div className="border-2  w-full h-full px-4 md:px-8 py-12 rounded-md shadow-xl relative z-50 fade-in-left">
          {/* back */}
          <div>
            <Link to="/subscription">
              <IoReturnDownBackOutline size={25} />
            </Link>
          </div>

          {/* logo */}
          <div className="flex justify-center">
            <img src={Logo} alt="" className="" />
          </div>

          <div className="text-center text-[24px] italic pb-8">
            <div>
              {" "}
              {subscriptionuser?.company_name || " "}
            </div>
          </div>

          <form
            className=" lg:text-center"
            onSubmit={handleSubmit(submitHandler)}
          >
            <div>
              <label
                style={{ display: "inline-block", width: "177px" }}
                className=""
              >
                Company Name:
              </label>

              <input
                {...register("company_name")}
                name="company_name"
                value={subscriptionuser?.company_name}
                className=" w-full lg:w-1/2 border-slate-300  placeholder:text-sm placeholder:text-slate-300 rounded-md focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
                type="text"
                placeholder=" "
                required="yes"
              />
            </div>

            <div className="pt-6">
              <label
                style={{ display: "inline-block", width: "177px" }}
                className=""
              >
                Company Address:
              </label>

              <input
                {...register("company_address")}
                name="company_address"
                value={subscriptionuser?.company_address}
                className=" w-full lg:w-1/2 border-slate-300 placeholder:text-sm placeholder:text-slate-300 rounded-md focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
                type="text"
                placeholder=""
                required="yes"
              />
            </div>

            <div className="pt-6">
              <label
                style={{ display: "inline-block", width: "177px" }}
                className=""
              >
               Company Email:
              </label>

              <input
                {...register("company_email")}
                value={subscriptionuser?.company_email}
                name="company_email"
                className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
                type="text"
                placeholder=""
                required="yes"
              />
            </div>

            <div className="pt-6">
              <label
                style={{ display: "inline-block", width: "177px" }}
                className=""
              >
                RC Number:
              </label>

              <input
                {...register("rc_number")}
                value={subscriptionuser?.rc_number}
                name="rc_number"
                className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
                type="text"
                placeholder=""
                required="yes"
              />
            </div>

            <div className="flex justify-center items-center cursor-pointer pt-5">
              <button
                type="submit"
                className="bg-pink-200 italic px-12 py-4 rounded-md shadow-sm cursor-not-allowed"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ViewSubscriptionpage ;
