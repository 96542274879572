import React, { useContext, useEffect, useState } from "react";
import Layout from "../component/Layout Component/Layout";
import { Link, useNavigate } from "react-router-dom";
import { Doctorappointment } from "../component/Layout Component/Cards";
import InputForm from "../component/Form Component/InputForm";
import useDoctor from "../hooks/useDoctors";
import useNotification from "../hooks/useNotifications";
import { BiSearchAlt } from "react-icons/bi";
import { BiEdit } from "react-icons/bi";
import { TiDeleteOutline } from "react-icons/ti";

import { DataContext } from "../context/Data/DataContext";
import useScheduler from "../hooks/useScheduler";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Client, Account, Databases, Query, ID } from "appwrite";

const animatedComponents = makeAnimated();

const client = new Client();

client
  .setEndpoint(process.env.REACT_APP_API)
  .setProject(process.env.REACT_APP_PROJECT_KEY);
const DBID = process.env.REACT_APP_APPWRITE_DATABASE_ID;
const schedulerCollection = "6414289cbe2d598a5231";
const account = new Account(client);

const DB = new Databases(client);

const Schedular = () => {
  const { getAllDoctorsList, allDoctors } = useDoctor();
  const { allscheduler, getAllSchedulerList } = useScheduler();
  const navigate = useNavigate();
  const { scheduledoc, setSecheduledoc } = useContext(DataContext);

  const { date, doctors, starttime, endtime } = scheduledoc;
  const { handleError, handleSuccess } = useNotification();
  const [loading, setLoading] = useState(false);

  // Set the selected doctor state
  const [selectedDoctor, setSelectedDoctor] = useState([]);
  const [selected, setSelected] = useState([]);
  const [dataSaved, setDataSaved] = useState(false);



  const [searchResult, setSearchResult] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    const filteredScheduler= allscheduler.filter(
      (scheduler) =>
        scheduler.first_name.toLowerCase().includes(value.toLowerCase()) ||
        scheduler.date.toLowerCase().includes(value.toLowerCase()) ||
        scheduler.last_name.toLowerCase().includes(value.toLowerCase())
    );
    setSearchResult(filteredScheduler);
  };



  useEffect(() => {
    console.log("all patients are => ", allscheduler );
    setSearchResult(allscheduler);
  }, [allscheduler]);



  const doctorOptions = allDoctors.map((doctor) => ({
    value: doctor?.userID,
    label: `${doctor?.first_name} ${doctor?.last_name}`,
  }));

 



  



  const saveData = async (data) => {
    data = {
      ...data,
      doctors: JSON.stringify(data.doctors),
    };

    console.log({ data });

    try {
      const result2 = await DB.createDocument(
        DBID,
        schedulerCollection,
        ID.unique(),
        data
      );
      console.log("Data saved:", result2);
      setDataSaved(true);

      if (!result2) throw new Error("failed");

      console.log("sucessful");

      // await createOrganizationUser({ userID: result1.$id, OrgUserID: result2.$id })
      // handleSuccess("Organization created successfully");
    } catch (error) {
      console.log({ error });
      handleError(error);
      return false;
    } finally {
      setLoading(false);
      handleSuccess("Saved Sucessfully");
    }
  };

  // const getSchedule = async (id) => {
  //   const result = await DB.getDocument(DBID, schedulerCollection, id);
  //   console.log({ result });
  //   const schedule = {
  //     ...result,
  //     doctors: JSON.parse(result.doctors),
  //   };
  //   console.log({ schedule });
  // };

  useEffect(() => {
    getAllDoctorsList();
    getAllSchedulerList();
  }, []);

  // Handle doctor selection
  const handleDoctorChange = (selected) => {
    console.log('doctor selected is , ',selected);
    setSelectedDoctor(selected);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('here selected doc is => ', { ...scheduledoc, doctors: selectedDoctor })
    setSecheduledoc({ ...scheduledoc, doctors: selectedDoctor });
    saveData({ ...scheduledoc, doctors: selectedDoctor });
  };

  const changeHandler = (e) => {
    setSecheduledoc({
      ...scheduledoc,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Layout>
      <Doctorappointment />
      <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-3">
        <div>
          <label
            htmlFor="date"
            className="block text-sm font-medium text-consultblack"
          >
            Pick Date
          </label>

          <InputForm
            name={"date"}
            //defaultValue={}
            onChange={changeHandler}
            value={date}
            type="date"
            placeholder="DD/MM/YYYY"
            required="yes"
            size=" md:w-3/5"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-consultblack">
            Pick Start Time
          </label>

          <InputForm
            name={"starttime"}
            //defaultValue={}
            onChange={changeHandler}
            value={starttime}
            type="time"
            placeholder=""
            required="yes"
            size=" md:w-3/5"
          />
        </div>

        <div>
          <label
            htmlFor="endtime"
            className="block text-sm font-medium text-consultblack"
          >
            Pick End Time
          </label>

          <InputForm
            name={"endtime"}
            //defaultValue={}
            onChange={changeHandler}
            value={endtime}
            type="time"
            placeholder=""
            required="yes"
            size=" md:w-3/5"
          />
        </div>
        </div>
        
      {/* Render the doctor select component */}
      <Select
        name={"doctors"}
        values={doctors}
        value={selectedDoctor}
        closeMenuOnSelect={false}
        components={animatedComponents}
        isMulti
        options={doctorOptions}
        onChange={handleDoctorChange}
      />

      <div className="flex justify-end pr-12 pb-32 pt-32">
        <button
          type="submit"
          onClick={handleSubmit}
          className="p-2 px-6 text-sm h-8 md:h-10 border flex justify-end border-red-500 text-nextgray bg-white hover:bg-addred  hover:text-white rounded-md"
        >
          Save
        </button>
        </div>
        
      </form>
      
      <div>

      <div className="flex justify-end px-8 md:px-2 mb-5">
        <label class="relative block mt-6 md:mt-8 w-1/2 md:w-1/2 lg:w-1/5">
          <span class="sr-only ">Search</span>
          <div className="flex">
            <div class="absolute inset-y-0 left-0 pt-3 pl-2 text-searchicon z-10">
              <BiSearchAlt className="" />
            </div>
            <input
              class=" placeholder:text-searchicon block bg-white w-full border border-slate-300 rounded-md py-2 pl-6 pr-2 shadow-sm   drop-shadow-sm placeholder-nextgray focus:outline-none focus:border-consultbg focus:ring-consultbg focus:ring-1 sm:text-sm"
              placeholder="Search"
              type="text"
              name="search"
              value={searchValue}
              onChange={handleSearchChange}
            />
          </div>
        </label>
      </div>

        <div className=" px-8 md:px-2">
          <div className="w-full h-fit border border-navgray">
            <div className="overflow-x-auto w-full">
              <table className="table w-full">
                {/* <!-- head --> */}
                <thead>
                  <tr className="bg-pink-200">
                    <th className="bg-pink-200">
                      <div className="bg-pink-200">#</div>
                    </th>
                    <th className="bg-pink-200 center">Date</th>
                    <th className="bg-pink-200">Doctors</th>
                    <th className="bg-pink-200">Start Time</th>
                    <th className="bg-pink-200">End Time</th>
                    {/* <th className="bg-pink-200"></th>
                    <th className="bg-pink-200"></th> */}
                  </tr>
                </thead>
                  <tbody>
                    {/* <!-- row 1 --> */}
                {searchResult?.map((elem, index) => (

                    <tr>
                      <th>
                        <label>
                        <div>{ index +1}</div>
                        </label>
                      </th>
                      <td>
                        <th>{elem?.date}</th>
                      </td>
                      <td>
                      <div className="font-bold">{elem?.doctors?.map(d=>d.label).join(",",) }</div>
                      </td>
                      <td>
                        <div className="">{elem?.starttime}</div>
                      </td>
                      <td>{elem?.endtime}</td>
                      {/* <td className="flex justify-around items-center text-center mt-4">
                        <BiEdit size={25} className="" />
                        <TiDeleteOutline size={35} className="" />
                        <br />
                      </td> */}
                    </tr>
                 ))}
                  </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Schedular;
