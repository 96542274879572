import React, { useState, useContext } from "react";
//
import { Link, useLocation } from "react-router-dom";

//
import { MdSick, MdOutlineRepeatOneOn } from "react-icons/md";
import { FaUserMd } from "react-icons/fa";
import { VscOrganization } from "react-icons/vsc";
import { GiMoneyStack } from "react-icons/gi";
import { RiHealthBookFill } from "react-icons/ri";
import { BiCalendar } from "react-icons/bi";


import { LogoutSvg } from "../../assets/SVGs/SVG.js";
import { Settingicon } from "../../assets/SVGs/SVG.js";

import useAuth from "../../hooks/useAuth.jsx";

//
import {
  Pinksettingsvg,
} from "../../assets/SVGs/SVG.js";

//

const Sidebar = () => {
  // route
  const location = useLocation();
  const { logoutUser } = useAuth();

  return (
    <div className=" py-3 md:py-12 pr-8 bg-navbg shadow-sm h-full hidden lg:block ">
      {/* Patients */}
      <div className="mt- mb-6">
        <Link to="/patients" className="mb-5">
          <div
            className={`flex pb-4 pt-4 rounded-r-lg cursor-pointer pl-8 ${
              location.pathname === "/patients" ? "activeTab" : "text-grayon"
            }`}
          >
            {location.pathname === "/patients" ? (
              <MdSick size={25} styles="w-8 bg-sidebg" />
            ) : (
              <MdSick size={25} className="w-8 stroke-gray-500 " />
            )}

            <span
              className={`text-[12px] md:text-[18px] lg:text-[18px] pl-3.5  `}
            >
              Patients
            </span>
          </div>
        </Link>
      </div>

      {/* Doctors */}
      <div className="mt-6 mb-6">
        <Link to="/doctors" className="mb-5">
          <div
            className={`flex pb-4 pt-4 rounded-r-lg cursor-pointer pl-8 ${
              location.pathname === "/doctors" ? "activeTab" : "text-grayon"
            }`}
          >
            {location.pathname === "/doctors" ? (
              <FaUserMd size={25} styles="w-8 bg-sidebg" />
            ) : (
              <FaUserMd size={25} className="w-8 stroke-gray-500 " />
            )}

            <span
              className={`text-[12px] md:text-[18px] lg:text-[18px] pl-3.5  `}
            >
              Doctors
            </span>
          </div>
        </Link>
      </div>

      {/* Organizations */}
      <div className="mt-6 mb-6">
        <Link to="/organizations" className="mb-5">
          <div
            className={`flex pb-4 pt-4 rounded-r-lg cursor-pointer pl-8 ${
              location.pathname == "/organizations"
                ? "activeTab"
                : "text-grayon"
            }`}
          >
            {location.pathname == "/organizations" ? (
              <VscOrganization size={25} styles="w-8 bg-sidebg" />
            ) : (
              <VscOrganization size={25} className="w-8 stroke-gray-500 " />
            )}

            <span
              className={`text-[12px] md:text-[18px] lg:text-[18px] pl-3.5  `}
            >
              Organizations
            </span>
          </div>
        </Link>
      </div>

      {/* Consultation */}
      <div className="mt-6 mb-6">
        <Link to="/consultation" className="mb-5">
          <div
            className={`flex pb-4 pt-4 rounded-r-lg cursor-pointer pl-8 ${
              location.pathname == "/consultation" ? "activeTab" : "text-grayon"
            }`}
          >
            {location.pathname == "/consultation" ? (
              <MdOutlineRepeatOneOn size={25} styles="w-8 bg-sidebg" />
            ) : (
              <MdOutlineRepeatOneOn
                size={25}
                className="w-8 stroke-gray-500 "
              />
            )}

            <span
              className={`text-[12px] md:text-[18px] lg:text-[18px] pl-3.5  `}
            >
              Consultations
            </span>
          </div>
        </Link>
      </div>

      {/* Subscription */}
      <div className="mt-6 mb-6">
        <Link to="/subscription" className="mb-5">
          <div
            className={`flex pb-4 pt-4 rounded-r-lg cursor-pointer pl-8 ${
              location.pathname == "/subscription" ? "activeTab" : "text-grayon"
            }`}
          >
            {location.pathname == "/subscription" ? (
              <GiMoneyStack size={25} styles="w-8 bg-sidebg" />
            ) : (
              <GiMoneyStack size={25} className="w-8 stroke-gray-500 " />
            )}

            <span
              className={`text-[12px] md:text-[18px] lg:text-[18px] pl-3.5  `}
            >
              Subscriptions
            </span>
          </div>
        </Link>
      </div>

      {/* Subscription plan*/}
      <div className="mt-6 mb-6">
        <Link to="/subplan" className="mb-5">
          <div
            className={`flex pb-4 pt-4 rounded-r-lg cursor-pointer pl-8 ${
              location.pathname == "/subplan" ? "activeTab" : "text-grayon"
            }`}
          >
            {location.pathname == "/subplan" ? (
              <GiMoneyStack size={25} styles="w-8 bg-sidebg" />
            ) : (
              <GiMoneyStack size={25} className="w-8 stroke-gray-500 " />
            )}

            <span
              className={`text-[12px] md:text-[18px] lg:text-[18px] pl-3.5  `}
            >
              Subcription Plans
            </span>
          </div>
        </Link>
      </div>


      {/* Health Tips*/}
      <div className="mt-6 mb-6">
        <Link to="/healthtip" className="mb-5">
          <div
            className={`flex pb-4 pt-4 rounded-r-lg cursor-pointer pl-8 ${
              location.pathname == "/healthtip" ? "activeTab" : "text-grayon"
            }`}
          >
            {location.pathname == "/healthtip" ? (
              <RiHealthBookFill size={25} styles="w-8 bg-sidebg" />
            ) : (
              <RiHealthBookFill size={25} className="w-8 stroke-gray-500 " />
            )}

            <span
              className={`text-[12px] md:text-[18px] lg:text-[18px] pl-3.5  `}
            >
              Health Tips
            </span>
          </div>
        </Link>



        <div className="mt-6 mb-6">
        <Link to="/scheduler" className="mb-5">
          <div
            className={`flex pb-4 pt-4 rounded-r-lg cursor-pointer pl-8 ${
              location.pathname == "/scheduler" ? "activeTab" : "text-grayon"
            }`}
          >
            {location.pathname == "/schedular" ? (
              <BiCalendar size={25} styles="w-8 bg-sidebg" />
            ) : (
              <BiCalendar size={25} className="w-8 stroke-gray-500 " />
            )}

            <span
              className={`text-[12px] md:text-[18px] lg:text-[18px] pl-3.5  `}
            >
              Scheduler
            </span>
          </div>
          </Link>
          </div>
      </div>

      {/* setting */}
      <div className="mt-6 mb-6">
        <Link to="/settings" className="mb-5">
          <div
            className={`flex pb-4 pt-4 rounded-r-lg cursor-pointer pl-8 ${
              location.pathname == "/settings" ? "activeTab" : "text-grayon"
            }`}
          >
            {location.pathname == "/settings" ? (
              <div className="bg-sidebg">
                <Pinksettingsvg className="w-8 " />
              </div>
            ) : (
              <Settingicon alt="" className="w-8" />
            )}

            <span
              className={`text-[12px] md:text-[18px] lg:text-[18px] pl-3.5  `}
            >
              Settings
            </span>
          </div>
        </Link>
      </div>

      {/* logout */}
      <div className='mt-6 mb-6'>
        <button
          onClick={() => {
            logoutUser();
          }}
        >
          <div className={`flex pb-4 pt-4 rounded-r-lg cursor-pointer pl-8`}>
            <LogoutSvg alt='' className='w-8' />

            <span className={`text-[12px] md:text-[18px] lg:text-[18px] pl-3.5  text-grayon `}>Logout</span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
