import React, { useState, useEffect } from "react";
import Layout from "../component/Layout Component/Layout";
import {
  Healthtipscard,
} from "../component/Layout Component/Cards.jsx";

//
import { BiSearchAlt } from "react-icons/bi";
import Healthtiptable from "../component/Layout Component/healthtiptable";

const Healthtips = () => {
 

  return (
    <Layout>
      <Healthtipscard />

      <Healthtiptable />
    </Layout>
  );
};

export default Healthtips;
