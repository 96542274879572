import {useState, useContext } from "react";
import { UtilityContext } from "../context/Utility/UtilityContext";
import {  getSubusers, updateSubscriptionuserData} from "../services/subscription user/subscriptionusers";
import useNotification from "../hooks/useNotifications";
import { getOneOrganization, getOneSubscription } from "../utils/appwrite/appwriteConfig";


async function getSubUserInfo(subUser) {

    // get organization information
    const orgInfo = await getOneOrganization(subUser.orgID)
    if(!orgInfo) throw new Error("No organization")

    // get subscription information
    const subInfo = await getOneSubscription(subUser.subID)
    if(!subInfo) throw new Error("No subscription information")

    return {
        company_name: orgInfo.company_name,
        company_email: orgInfo.company_email,
        sub_plan: subInfo.Plan,
        head_count: subUser.head_count,
        time: subUser.time
    }
    
}


const useSubcriptionuser= () => {
  const { setShowLoader } = useContext(UtilityContext);
  const { handleError, handleSuccess } = useNotification();
  const [allSubscriptionsuser,setAllSubscriptionsuser] = useState([])

  const updateSubcriptionuser = async (id, data) => {
    setShowLoader(true);
    console.log('udpate in useSubscriptionuser',id,data);
    try {
       const updateRes = await updateSubscriptionuserData(id, data)
       console.log({updateRes})
       if(!updateRes.$id) throw new Error(`subscription user failed to be updated`)
       handleSuccess("Subscription user updated successfully");
       return true
    } catch (error) {
      console.log({error})
      handleError(error);
      return false
    } finally {
      setShowLoader(false);
    }
  };

  const getAllSubscriptionsuserList = async ()=>{
    const subscriptionsuser = await getSubusers()
    // console.log({subscriptionsuser})
    const subUsersInfo = await Promise.all(subscriptionsuser.documents.map(async (subUser)=>{
        return await getSubUserInfo(subUser)
    }))

    console.log({subUsersInfo})
    setAllSubscriptionsuser(subUsersInfo)
  }

  return {
    allSubscriptionsuser,
    updateSubcriptionuser,
    getAllSubscriptionsuserList
  };
};

export default useSubcriptionuser;
