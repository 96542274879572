import React from "react";
import Layout from "../component/Layout Component/Layout";
import { Consultationcard } from "../component/Layout Component/Cards";
import Consultinfotable from "../component/Layout Component/Consultinfotable";

//
import { BiSearchAlt } from "react-icons/bi";

const AdminConsult = () => {
  return (
    <Layout>
      <Consultationcard />
      {/* search */}
      
      <Consultinfotable />
    </Layout>
  );
};

export default AdminConsult;
