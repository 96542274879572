import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { IoReturnDownBackOutline } from "react-icons/io5";
import Logo from "../../assets/Photos/logo.png"



import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useSubcription from "../../hooks/useSubscription"


const EditSubscriptionpage = () => {

  const [edited, setEdited] = useState(null)
  const {  updateSubcription, allSubscriptions, getAllSubscriptionsList } = useSubcription();
  
  // console.log({subs:allSubscriptions})
  
  const navigate = useNavigate();
  const location = useLocation();
  
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get('id');
  
  // console.log({id})
  
  const subplan = allSubscriptions.find(plan=>plan.$id==id)
  // console.log({subplan})
  
  
  useEffect(() => {

    getAllSubscriptionsList();


 }, []);

  const validationSchema = Yup.object().shape({
    Plan: Yup.string().required("Update infromation"),
    amount: Yup.string().required("Update infromation"),
    title: Yup.string().required("Update infromation"),
   description: Yup.string().required("Update infromation"),
    body: Yup.string().required("Update infromation"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;




  const submitHandler = async (data) => {
    try {
    console.log({submitted:data});
      updateSubcription(id, data).then((res) => {
        console.log(res);
        reset();
        navigate("/subplan");
      });
    } catch (error) {
      console.log(error.message);

      return;
    }
  };

  const changeHandler = (e) => {
    setEdited({
      ...edited,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className=" flex justify-center items-center pt-24 px-12 md:px-40 bg-pink-100/30 ">
      <div className="border-2  w-full h-full px-4 md:px-8 py-12 rounded-md shadow-xl relative z-50 ">
        {/* back */}
        <div>
          <Link to="/subplan">
            <IoReturnDownBackOutline size={25} />
          </Link>
        </div>

        {/* logo */}
        <div className="flex justify-center">
          <img src={Logo} alt="" className="" />
        </div>

       <div className="text-center text-[24px] italic pb-8">
       <div>{subplan?.Plan}</div>
       </div>

        <form className=" lg:text-center" onSubmit={handleSubmit(submitHandler)}>

          <div>
            <label style={{display: "inline-block", width: "138px"}} className="">Plan Name:</label>
            
            <input
            {...register('Plan')}
              name="Plan"
              defaultValue={subplan?.Plan}
              className=" w-full lg:w-1/2 border-slate-300  placeholder:text-sm placeholder:text-slate-300 rounded-md focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
              type="text"
              placeholder=" Update Name"
              required="yes"
            />
            
          </div>

          <div className="pt-6">
            <label  style={{display: "inline-block", width: "138px"}}className="">Amount:</label>
         
           <input
            {...register('amount')}
            name="amount"
            defaultValue={subplan?.amount}
              className=" w-full lg:w-1/2 border-slate-300 placeholder:text-sm placeholder:text-slate-300 rounded-md focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
              type="text"
              placeholder="Update Amount"
              required="yes"
            />
           
          </div>

          <div className="pt-6">
            <label style={{display: "inline-block", width: "138px"}} className="">Title:</label>
           
           <input
            {...register('title')}
            defaultValue={subplan?.title}
            name="title"
              className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
              type="text"
              placeholder="Update Title"
              required="yes"
            />
          
          </div>

          <div className="pt-6">
            <label style={{display: "inline-block", width: "138px"}} className="">Description:</label>
           
            <textarea
              {...register('description')}
              defaultValue={subplan?.description}
              name="description"
              className="textarea textarea-bordered  w-full lg:w-1/2  rounded-md placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300"
              placeholder="Update description"
              required="yes"
            ></textarea>
           
          </div>

          <div className="pt-6">
            <label style={{display: "inline-block", width: "138px"}} className="">Body:</label>
           
           <textarea
            {...register('body')}
            defaultValue={subplan?.body}
            name="body"
              className="textarea textarea-bordered w-full lg:w-1/2 rounded-md placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300"
              placeholder="Update body"
              required="yes"
            ></textarea>
         
          </div>

          <div className="flex justify-center items-center cursor-pointer pt-5">
                <button
                  type="submit"
                  className="bg-pink-200 italic px-12 py-4 rounded-md shadow-sm"
                >
                  Update
                </button>
              </div>
        </form>
      </div>
    </div>
  );
};

export default EditSubscriptionpage;
