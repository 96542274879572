import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../../src/assets/Photos/logo.png";
import { LogoutSvg } from "../../assets/SVGs/SVG.js";


import useAuth from "../../hooks/useAuth.jsx";

//
import { MdSick, MdOutlineRepeatOneOn } from "react-icons/md";
import { FaUserMd } from "react-icons/fa";
import { FcOrganization } from "react-icons/fc";
import { VscOrganization } from "react-icons/vsc";
import { GiMoneyStack } from "react-icons/gi";

const Slidesidebar = (props) => {
  // route
  const location = useLocation();
  const { logoutUser } = useAuth();



  return (
    <div>
      <div className="drawer">
        <input id="my-drawer" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content">
          {/* <!-- Page content here --> */}
          {props.children}
        </div>
        <div className="drawer-side">
          <label htmlFor="my-drawer" className="drawer-overlay"></label>

          <div className="menu p-4 w-80 bg-base-100 italic cursor-pointer pl-6">
            <div className="flex justify-center pt-">
              <div className=" w-1/2">
                <img src={Logo} alt="" className="" />
              </div>
            </div>

            <div className="pb-6 text-center text-black">
              Doci Accessible Healthcare
            </div>

            {/* patient */}
           
              <Link to="/patients">
                <div className="text-lg pb-4 pt-12 text-center">
                  <a>Patient</a>
                </div>
              </Link>
            

            {/* doctors */}
            <Link to="/doctors">
              <div className="text-lg py-4 text-center">
                <a>Doctors</a>
              </div>
            </Link>

            {/* organizations */}
            
            <Link to="/organizations">
              <div className="text-lg py-4 text-center">
                <a>Organizations</a>
              </div>
            </Link>
           

            {/* consultations */}
            <Link to="/consultation">
              <div className="text-lg py-4 text-center">
                <a>Consultations</a>
              </div>
            </Link>

            {/* subcription */}
            <Link to="/subscription">
              <div className="text-lg py-4 text-center">
                <a>subcription</a>
              </div>
            </Link>

            {/* settings */}
            <Link to="/settings">
              <div className="text-lg py-4 text-center">
                <a>Settings</a>
              </div>
            </Link>

            {/* logout */}
            <div className=""
                 onClick={() => {
                  logoutUser();
                }}
                
                  >
                    <div className=" cursor-pointer text-lg py-4 text-center">
                      Logout
                    </div>
                  </div>
                </div>
          
        </div>
      </div>
    </div>
  );
};

export default Slidesidebar;
