import React, { useState } from "react";
import Frame from "../assets/Photos/Frame.png";
import logo from "../assets/Photos/logo.png";
import Photo from "../assets/Photos/Nextillustration.png";
import labby from "../assets/Photos/labby.webp";

//
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useAuth from "../hooks/useAuth";
//
import Loginform from "../component/Form Component/Loginform";

//
import { Link, useLocation, useNavigate } from "react-router-dom";

const Login = (props) => {
  // route
  const location = useLocation();
  const navigate = useNavigate();
  const { loginUser } = useAuth();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .max(32, "Password must not exceed 32 characters")
      .required("Please Enter your password"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const submitForm = async (data) => {
    console.log(data);
    loginUser(data.email, data.password).then((res) => {
      navigate("/patients");
    });
  };

  return (
    <div className="h-full overflow-y-hidden">
      <div className="lg:flex h-full ">
        {/* left screen */}
        <div className="h-screen bg-mygray lg:w-[258%] lg:block hidden relative">
          {/* logo */}
          <div className="absolute w-96">
            <img src={Frame} alt="" />
          </div>

          {/*  */}
          <div className="">
            <div className="h-screen">
              <img
                style={{}}
                src={labby}
                alt=""
                srcSet=""
                className="h-screen"
              />
            </div>
          </div>
        </div>

        {/* right screen */}
        
        <div className="w-full sm:w-full lg:1/5  h-full sm:text-center  lg:text-start px-12 md:px-8 lg:px-12">
          {/* logo */}
          <div className="flex justify-center pt-24">
            <div className="w-24 ">
              <img src={logo} alt="" />
            </div>
          </div>
            <div className="text-center text-lg text-nextgray">
              DOCi Assessible Healthcare
            </div>

            <div className=" mb-one pt-12 sm:pt- md:pt- lg:pt- text-bold ">
              <h1>
                <span className=" text-[18px] font-bold text-transparent bg-clip-text bg-gradient-to-r from-red-500  via-red-600 to-red-700">
                  Welcome back to DociHealthcare!
                </span>
                👋🏻
              </h1>
            </div>
            <h2 className="text-nextgray text-[14px] font-medium mb-six">
              Please sign-up to acess your account and get started
            </h2>

            {/* form */}

            {/* <Loginform/> */}
            <form onSubmit={handleSubmit(submitForm)}>
              <div>
                <p>{errors?.email?.message}</p>
              </div>
              <div className="">
                <label
                  htmlFor="first-name"
                  className="block text-sm  font-medium text-nextgray"
                >
                  Email Address
                </label>
                <input
                  {...register("email")}
                  type="email"
                  placeholder="someone@example.com"
                  autoComplete="true"
                  className="mt-1 mb-six block w-full h-14 rounded-md text-left pl-2 text-sm  border-bordergray border sm:text-sm"
                />

                <br />
                <div className="flex justify-between">
                  <label
                    htmlFor="first-name"
                    className=" block text-sm font-medium text-nextgray"
                  >
                    Password
                  </label>

                  <div onClick={() => navigate('/forgot-password')} className=" cursor-pointer flex justify-end text-sm font-medium text-transparent bg-clip-text bg-gradient-to-r from-red-500  via-red-600 to-red-700">
                    Forgot Password?
                  </div>
                </div>
                <input
                  type="password"
                  {...register("password")}
                  placeholder="********************"
                  autoComplete="given-name"
                  className="mt-1 block w-full h-14 rounded-md text-left pl-2 text-xl  border-bordergray border    sm:text-sm"
                />
                <br />
                <input type="checkbox" />
                <span className="text-nextgray pl-6 font-medium">
                  Remember me
                </span>
              </div>
              <br />
              <button
                type="submit"
                loading={loading}
                className=" bg-gradient-to-r from-red-500  via-red-600 to-red-700 inline-flex justify-center w-full h-12 rounded-lg text-white py-3"
              >
                Login
              </button>
              <br />

              <br />
              {/* <div className="text-center">
                <h4 className="inline">New on our platform?</h4>
                <Link to="/signup">
                  <h5 className="ml-4 inline text-bold text-transparent bg-clip-text bg-gradient-to-r from-red-500  via-red-600 to-red-700">
                    Create an account
                  </h5>
                </Link>
              </div> */}
            </form>

            {/*  */}
          </div>
        
      </div>
    </div>
  );
};

export default Login;
