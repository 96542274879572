import React, {useContext, useEffect} from "react";
//
import { Link, useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { IoReturnDownBackOutline } from "react-icons/io5";

//
import Frame from "../../assets/Photos/Frame.png";
import { DataContext } from "../../context/Data/DataContext";
import FormInput from "./Input";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useDoctor from "../../hooks/useDoctors";
import { createDoctor } from "../../utils/appwrite/appwriteConfig";
import useNotification from "../../../src/hooks/useNotifications";
import { UtilityContext } from "../../Utility/UtilityContext";

const Doctorform = (props) => {
  const { createDoctorAccount } = useDoctor();
  // const { setShowLoader } = useContext(UtilityContext);
  const { handleError, handleSuccess } = useNotification();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("Enter doctor's first name"),
    lastname: Yup.string().required("Enter doctor's last name"),
    email: Yup.string().required("Enter doctor's email"),
    title: Yup.string().required("Enter doctor's title title"),
    organization: Yup.string().required("Enter doctor's organization name"),
    specialization: Yup.string().required("Enter doctor's field of specialization"),
    designation: Yup.string().required("Please enter a designation"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;


  const submitHandler = async (data) => {
    console.log(data);
    try {
      const res = await createDoctorAccount(data)
      console.log(res);
      reset();
      reset();
      navigate("/doctors");
      
    } catch (error) {
      console.log(error.message);
      
      return;
    }
     

  };

  return (
    <div className=''>
      <div className=' flex justify-center items-center pt-24 px-12 md:px-40 bg-pink-100/30 '>
        <div className='border-2  w-full h-full px-4 md:px-8 py-12 rounded-md shadow-xl relative z-50 '>
          {/* back */}
          <div>
            <Link to='/doctors'>
              <IoReturnDownBackOutline size={25} />
            </Link>
          </div>

          {/* logo */}
          <div className=''>
            <img src={Frame} alt='' className='w-1/2 lg:w-1/5' />
          </div>
          {/* introduction */}
          <div className='flex justify-between font-medium'>
            <div className='text-start'>Fill in Doctor's information</div>
            <div className='text-end'>required</div>
          </div>
          
          
          <form onSubmit={handleSubmit(submitHandler)}>
            <div>
              <p>{errors.message}</p>
            </div>
            {/* title */}
            <div className='custom-select w-1/5  pt-2' style={{}}>
              <label htmlFor='title' className='block text-sm  font-medium  text-consultblack'>
                Title
              </label>
              <select
                {...register("title")}
                name={"title"}
                placeholder='Title'
                className='   h-12 rounded-md text-left pl-2 text-sm sm:text-sm  bg-white border drop-shadow-sm border-slate-300 placeholder-nextgray focus:outline-none focus:border-consultbg focus:ring-consultbg mb-12'
              >
                <option className='py-2' value={"Mr"}>
                  Dr
                </option>
                {/* <option value={"Miss"}>Miss</option>
                <option value={"Mrs"}>Mrs</option>
                <option value={"Master"}>Master</option> */}
              </select>
            </div>

            <div className=' md:grid grid-cols-2  '>
              <div className=' pb-12 md:pr-8'>
                <label htmlFor='first-name' className='block text-sm  font-medium  text-consultblack'>
                  First Name
                </label>

                <FormInput name={"firstname"} type='text' placeholder='Lhezie' required='yes'  size='' register={register} />
              </div>

              {/* last name */}
              <div className='  md: lg:pr-0 pb-12 md:pl-8'>
                <div>
                  <label htmlFor='lastname' className='block text-sm  font-medium  text-consultblack'>
                    Last Name
                  </label>
                  <FormInput name={"lastname"} type='text' placeholder='Andy' required='yes'  size='' register={register} />
                </div>
              </div>

              {/* organization */}
              <div className=' pb-12 md:pr-8'>
                <label htmlFor='organization' className='block text-sm  font-medium  text-consultblack'>
                  Organization
                </label>

                <FormInput
                  name={"organization"}
                  type='text'
                  placeholder='Wellness Group of Doctors Ltd'
                  required='yes'
                  han
                  size=''
                  register={register}
                />
              </div>

              {/* email */}
              <div>
                <div className='  md: lg:pr-0 pb-6 md:pl-8'>
                  <label htmlFor='email' className='block text-sm  font-medium  text-consultblack'>
                    Doctor's Email
                  </label>
                  <FormInput name={"email"} type='email' placeholder='someone@mail.com' required='yes'  size='' register={register} />
                </div>
              </div>

              {/* specialization */}
              <div className=' pb-12 md:pr-8'>
                <label htmlFor='age' className='block text-sm  font-medium  text-consultblack'>
                  Specialization
                </label>

                <FormInput
                  name={"specialization"}
                  type='text'
                  placeholder='General Doctor / Gynaecologist'
                  required='yes'
                  han
                  size=''
                  register={register}
                />
              </div>

              {/* designation */}
              <div>
                <div className='  md: lg:pr-0 pb-6 md:pl-8'>
                  <label htmlFor='designation' className='block text-sm  font-medium  text-consultblack'>
                    Designation
                  </label>
                  <FormInput name={"designation"} type='text' placeholder='Virtual Doctor' required='yes' han size='' register={register} />
                </div>
              </div>
            </div>

            <div className='flex justify-center items-center cursor-pointer'>
              <button type='submit' className='bg-pink-200 italic px-12 py-4 rounded-md shadow-sm'>
                Add doctor
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Doctorform;
