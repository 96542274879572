import { Account, Client, Databases, ID, Query } from "appwrite";

// const client = new Client();

export const client = new Client()
  .setEndpoint(process.env.REACT_APP_API)
  .setProject(process.env.REACT_APP_PROJECT_KEY);

client
  .setEndpoint(process.env.REACT_APP_API)
  .setProject(process.env.REACT_APP_PROJECT_KEY);
const DBID = process.env.REACT_APP_APPWRITE_DATABASE_ID;
const DoctorsCollection = "64f2288e9964012fa26b";
const PatientCollection = "64f228e0bd0ac4c7d25a";
const AppointmentCollection = "64f22907bfce4d8bf5aa";
const OrganizationCollection = "64f2308a24d074ca8551";
const OrgUserCollection = "64f2313106cc19558711";
const SubcriptionCollection = "64f23156b51f2b23b152";
const SUbUserCollection = "64f2317f295eaf8174d7";
const HealthtipsCollections = "64f2319f7276c31223a7";
const ConsultationsCollection = "64f229620a87fb7619d2";
const schedulerCollection = "64f22981471300918245";
const adminCollection = "64f231edc92e767c3a4d";
const account = new Account(client);

const DB = new Databases(client);

function getAccount() {
  return account.get();
}

function createEmailSession(email, password) {
  return account.createEmailSession(email, password);
}

function deleteSession() {
  console.log("deleteSession");
  return account.deleteSession("current");
}

function doctorAccountDetails(userID) {
  return DB.listDocuments(DBID, DoctorsCollection, [
    Query.equal("userID", userID),
  ]);
}

function adminAccountDetails(userID) {
  return DB.listDocuments(DBID, adminCollection, [
    Query.equal("userID", userID),
  ]);
}

function getAllAppointment() {
  return DB.listDocuments(DBID, AppointmentCollection);
}

function getAllDoctors() {
  return DB.listDocuments(DBID, DoctorsCollection);
}

function createUserAccount(email, name) {
  const password = "12345Doci@";
  console.log(email, password, name);
  return account.create(ID.unique(), email, password, name);
}

function createDoctor({
  userID,
  first_name,
  last_name,
  designation,
  specialization,
  organization,
  email,
}) {
  console.log(`new doctor email is ${email}`);
  return DB.createDocument(DBID, DoctorsCollection, ID.unique(), {
    userID,
    first_name,
    last_name,
    designation,
    email,
    specialization,
    organization,
  });
}

function updateDoctor(id, data) {
  return DB.updateDocument(DBID, DoctorsCollection, id, data);
}

function getOneOrganization(orgId) {
  return DB.getDocument(DBID, OrganizationCollection, orgId);
}

function getAllOrganizations() {
  return DB.listDocuments(DBID, OrganizationCollection);
}

function getAllAdmins() {
  return DB.listDocuments(DBID, adminCollection);
}

function getOneAdmin(adminId) {
  return DB.getDocument(DBID, adminCollection, adminId);
}

function updateAdmin(id, data) {
  return DB.updateDocument(DBID, adminCollection, id, data);
}

function createOrgUserAccount({ userID, email, name, password }) {
  // const password = "12345Doci@";

  console.log(email, password, name);
  return account.create(ID.unique(), email, password, name);
}

function createOrganization({
  company_name,
  company_address,
  company_email,
  rc_number,
  status = 1,
}) {
  return DB.createDocument(DBID, OrganizationCollection, ID.unique(), {
    company_name,
    company_address,
    company_email,
    rc_number,
    status,
  });
}

function createOrgUser({ userID, OrgUserID }) {
  return DB.createDocument(DBID, OrgUserCollection, ID.unique(), {
    userID,
    orgID: OrgUserID,
  });
}

function updateOrganization(id, data) {
  return DB.updateDocument(DBID, OrganizationCollection, id, data);
}

function updatePatient(id, data) {
  return DB.updateDocument(DBID, PatientCollection, id, data);
}

function getAllPatients() {
  return DB.listDocuments(DBID, PatientCollection);
}

function getOnePatient(id) {
  return DB.getDocument(DBID, PatientCollection, id);
}

function getOneDoctor(id) {
  return DB.getDocument(DBID, DoctorsCollection, id);
}

function updateSubscription(id, data) {
  return DB.updateDocument(DBID, SubcriptionCollection, id, data);
}

function getOneSubscription(subId) {
  return DB.getDocument(DBID, SubcriptionCollection, subId);
}

function getAllSubscriptions() {
  return DB.listDocuments(DBID, SubcriptionCollection);
}

function getAllConsultations() {
  return DB.listDocuments(DBID, ConsultationsCollection);
}

function getAllScheduler() {
  return DB.listDocuments(DBID, schedulerCollection);
}

function updateScheduler(id, data) {
  return DB.updateDocument(DBID, schedulerCollection, id, data);
}

function updateConsultation(id, data) {
  return DB.updateDocument(DBID, ConsultationsCollection, id, data);
}

//
function updateHealthtip(id, data) {
  return DB.updateDocument(DBID, HealthtipsCollections, id, data);
}

function getOneHealthtip(tipId) {
  return DB.getDocument(DBID, HealthtipsCollections, tipId);
}

function getAllHealthtips() {
  return DB.listDocuments(DBID, HealthtipsCollections);
}

//

function getAllSubUsers() {
  return DB.listDocuments(DBID, SUbUserCollection);
}

function updateSubUsers(id, data) {
  return DB.updateDocument(DBID, SUbUserCollection, id, data);
}

export {
  adminAccountDetails,
  createDoctor,
  createEmailSession,
  createOrgUser,
  createOrgUserAccount,
  createOrganization,
  createUserAccount,
  deleteSession,
  doctorAccountDetails,
  getAccount,
  getAllAdmins,
  getAllAppointment,
  getAllConsultations,
  getAllDoctors,
  getAllHealthtips,
  getAllOrganizations,
  getAllPatients,
  getAllScheduler,
  getAllSubUsers,
  getAllSubscriptions,
  getOneAdmin,
  getOneDoctor,
  getOneHealthtip,
  getOneOrganization,
  getOnePatient,
  getOneSubscription,
  updateAdmin,
  updateConsultation,
  updateDoctor,
  updateHealthtip,
  updateOrganization,
  updatePatient,
  updateScheduler,
  updateSubUsers,
  updateSubscription,
};

export default client;
