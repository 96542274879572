import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { generalContext } from "../../context/Form/GeneralContext";
import { IoReturnDownBackOutline } from "react-icons/io5";
import Frame from "../../assets/Photos/Frame.png";
import Logo from "../../assets/Photos/logo.png";

import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useConsultations from "../../hooks/useConsultations";
import { getAllConsultations } from "../../utils/appwrite/appwriteConfig";

const ViewConsultation = () => {
  const [viewed, setViewed] = useState(null);
  const { getAllconsultationsList, allconsultations, updateConsultation } =
    useConsultations();

  // console.log({subs:allSubscriptions})

  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("id");

  // console.log({id})

  const consultation = allconsultations.find((plan) => plan.$id === id);
  // console.log({subplan})

  useEffect(() => {
    getAllconsultationsList();
  }, []);

  const validationSchema = Yup.object().shape({
    complaints: Yup.string().required("Enter patient's first name"),
    diagnosis: Yup.string().required("Enter patient's last name"),
    prescribedrug: Yup.string().required("Enter patient's date of birth"),
    rtest: Yup.string().required("Enter patient's prescribedrug"),
    sensoryOne: Yup.boolean().required("Enter patient's blood type"),
    sensoryNote: Yup.string().required("Enter patient's phone text"),
    integumentaryOne: Yup.boolean().required("Enter patient's sensoryNote"),
    integumentaryNote: Yup.string().required("Enter patient's weight"),
    circulatoryNote: Yup.string().required("Enter patient's height"),
    respiratoryOne: Yup.boolean().required("Please enter a designation"),
    respiratoryNote: Yup.string().required("Enter patient's height"),
    dentalOne: Yup.boolean().required("Enter patient's phone text"),
    dentalNote: Yup.string().required("Enter patient's sensoryNote"),
    psychosocialOne: Yup.boolean().required("Enter patient's weight"),
    psychosocialNote: Yup.string().required("Enter patient's height"),
    nutritionOne: Yup.boolean().required("Please enter a designation"),
    nutritionNote: Yup.string().required("Enter patient's phone text"),
    gitOne: Yup.boolean().required("Enter patient's sensoryNote"),
    gitNote: Yup.string().required("Enter patient's height"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const submitHandler = async (data) => {
    try {
      console.log({ submitted: data });
      updateConsultation(id, data).then((res) => {
        console.log(res);
        reset();
        navigate("/consultation");
      });
    } catch (error) {
      console.log(error.messintegumentaryNote);

      return;
    }
  };

  const changeHandler = (e) => {
    setViewed({
      ...viewed,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className=" flex justify-center items-center pt-24 px-12 md:px-40 transparent">
      <div className="border-2 w-full h-full px-4 md:px-8 py-12 rounded-md shadow-xl relative z-50 fade-in-left ">
        {/* back */}
        <div>
          <Link to="/consultation">
            <IoReturnDownBackOutline size={25} />
          </Link>
        </div>

        {/* logo */}
        <div className=" flex justify-center">
          <img src={Logo} alt="" className=" " />
        </div>

        <div className="text-center text-[24px] italic pb-8">
          <div>
            {(consultation?.patientFirstName || " ") +
              " " +
              (consultation?.patientLastName || " ")}
          </div>
        </div>

        <form className="lg:text-center" onSubmit={handleSubmit(submitHandler)}>


          {/* complaint */}
          <label
            style={{ display: "inline-block", width: "138px" }}
            className="pr-4"
          >
            Complaints:
          </label>
          <input
            {...register("complaints")}
            name="complaints"
            value={consultation?.complaints}
            className=" w-full lg:w-1/2 border-slate-300  placeholder:text-sm placeholder:text-slate-300 rounded-md focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
            type="text"
            placeholder=" "
            required="yes"
          />

          {/* diagnosis */}
          <div className="pt-6">
            <label
              style={{ display: "inline-block", width: "138px" }}
              className=""
            >
              Diagnosis:
            </label>

            <input
              {...register("diagnosis")}
              name="diagnosis"
              value={consultation?.diagnosis}
              className=" w-full lg:w-1/2 border-slate-300 placeholder:text-sm placeholder:text-slate-300 rounded-md focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
              type="text"
              placeholder=""
              required="yes"
            />
          </div>
           
          

          {/* prescribed drug */}
          <div className="pt-6">
            <label
              style={{ display: "inline-block", width: "138px" }}
              className=""
            >
              Prescribed Drug(s):
            </label>

            <input
              {...register("prescribedrug")}
              value={consultation?.prescribedrug || "None"}
              name="title"
              className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
              type="text"
              placeholder=""
              required="yes"
            />
          </div>
          <div className="pt-6">
            <label
              style={{ display: "inline-block", width: "138px" }}
              className=""
            >
              Blood Type:
            </label>

            <input
              {...register("rtest")}
              value={consultation?.rtest}
              name="title"
              className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
              type="text"
              placeholder=""
              required="yes"
            />
          </div>
          <div className="pt-6">
            <label
              style={{ display: "inline-block", width: "138px" }}
              className=""
            >
              Sensory Issues:
            </label>

            <input
              {...register("sensoryOne")}
              value={consultation?.sensoryOne}
              name="title"
              className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
              type="text"
              placeholder=""
              required="yes"
            />
          </div>
          <div className="pt-6">
            <label
              style={{ display: "inline-block", width: "138px" }}
              className=""
            >
              Note:
            </label>

            <input
              {...register("sensoryNote")}
              value={consultation?.sensoryNote}
              name="title"
              className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
              type="text"
              placeholder=""
              required="yes"
            />
          </div>
          <div className="pt-6">
            <label
              style={{ display: "inline-block", width: "138px" }}
              className=""
            >
              Integumentary Issues:
            </label>

            <input
              {...register("integumentaryOne")}
              value={consultation?.integumentaryOne}
              name="designation"
              className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
              type="text"
              placeholder=""
              required="yes"
            />
          </div>
          <div className="pt-6">
            <label
              style={{ display: "inline-block", width: "138px" }}
              className=""
            >
              Note:
            </label>

            <input
              {...register("integumentaryNote")}
              value={consultation?.integumentaryNote}
              name="designation"
              className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
              type="text"
              placeholder=""
              required="yes"
            />
          </div>
          <div className="pt-6">
            <label
              style={{ display: "inline-block", width: "138px" }}
              className=""
            >
              Respiratory Issues:
            </label>

            <input
              {...register("respiratoryOne")}
              value={consultation?.respiratoryOne}
              name="designation"
              className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
              type="text"
              placeholder=""
              required="yes"
            />
          </div>
          <div className="pt-6">
            <label
              style={{ display: "inline-block", width: "138px" }}
              className=""
            >
              Note:
            </label>

            <input
              {...register("respiratoryNote")}
              value={consultation?.respiratoryNote}
              name="designation"
              className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
              type="text"
              placeholder=""
              required="yes"
            />
          </div>
          <div className="pt-6">
            <label
              style={{ display: "inline-block", width: "138px" }}
              className=""
            >
              Circulatory Issues:
            </label>

            <input
              {...register("circulatoryOne")}
              value={consultation?.circulatoryOne}
              name="designation"
              className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
              type="text"
              placeholder=""
              required="yes"
            />
          </div>
          <div className="pt-6">
            <label
              style={{ display: "inline-block", width: "138px" }}
              className=""
            >
              Note:
            </label>

            <input
              {...register("circulatoryNote")}
              value={consultation?.circulatoryNote}
              name="designation"
              className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
              type="text"
              placeholder=""
              required="yes"
            />
          </div>
          <div className="pt-6">
            <label
              style={{ display: "inline-block", width: "138px" }}
              className=""
            >
              Dental Issues:
            </label>

            <input
              {...register("dentalOne")}
              value={consultation?.dentalOne}
              name="designation"
              className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
              type="text"
              placeholder=""
              required="yes"
            />
          </div>
          <div className="pt-6">
            <label
              style={{ display: "inline-block", width: "138px" }}
              className=""
            >
              Note:
            </label>

            <input
              {...register("dentalNote")}
              value={consultation?.dentalNote}
              name="designation"
              className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
              type="text"
              placeholder=""
              required="yes"
            />
          </div>{" "}
          <div className="pt-6">
            <label
              style={{ display: "inline-block", width: "138px" }}
              className=""
            >
              Psychosocial Issues:
            </label>

            <input
              {...register("psychosocialOne")}
              value={consultation?.psychosocialOne}
              name="designation"
              className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
              type="text"
              placeholder=""
              required="yes"
            />
          </div>{" "}
          <div className="pt-6">
            <label
              style={{ display: "inline-block", width: "138px" }}
              className=""
            >
              Note:
            </label>

            <input
              {...register("psychosocialNote")}
              value={consultation?.psychosocialNote}
              name="designation"
              className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
              type="text"
              placeholder=""
              required="yes"
            />
          </div>{" "}
          <div className="pt-6">
            <label
              style={{ display: "inline-block", width: "138px" }}
              className=""
            >
              Nutrition Issues:
            </label>

            <input
              {...register("nutritionOne")}
              value={consultation?.nutritionOne}
              name="designation"
              className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
              type="text"
              placeholder="None"
              required="yes"
            />
          </div>{" "}
          <div className="pt-6">
            <label
              style={{ display: "inline-block", width: "138px" }}
              className=""
            >
              Note:
            </label>

            <input
              {...register("nutritionNote")}
              value={consultation?.nutritionNote}
              name="designation"
              className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
              type="text"
              placeholder=""
              required="yes"
            />
          </div>{" "}
          <div className="pt-6">
            <label
              style={{ display: "inline-block", width: "138px" }}
              className=""
            >
              GIT Issues:
            </label>

            <input
              {...register("gitOne")}
              value={consultation?.gitOne}
              name="designation"
              className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
              type="text"
              placeholder="None"
              required="yes"
            />
          </div>{" "}
          <div className="pt-6">
            <label
              style={{ display: "inline-block", width: "138px" }}
              className=""
            >
               Note:
            </label>

            <input
              {...register("gitNote")}
              value={consultation?.gitNote}
              name="designation"
              className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
              type="text"
              placeholder=""
              required="yes"
            />
          </div>
         
          
          <div className="flex justify-center items-center cursor-pointer pt-5">
            <button
              type="submit"
              className="bg-pink-200 px-12 py-4 rounded-md shadow-sm cursor-not-allowed"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ViewConsultation;
