import { createContext, useState } from "react";

export const generalContext = createContext()
const Provider = generalContext.Provider

const GeneralProvider = (props)=>{
    const [active,setActive] = useState(false)

    return <Provider value={{active,setActive} }>
        {props.children}
    </Provider>
}

export default GeneralProvider;