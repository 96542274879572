import { useContext, useState } from "react";
import { UtilityContext } from "../context/Utility/UtilityContext";
import { updateAdminData, getAdmins} from "../services/admins/admins";
import { getAllAdmins } from "../utils/appwrite/appwriteConfig";
import useNotification from "../hooks/useNotifications";

const useAdmins = () => {
  const { setShowLoader } = useContext(UtilityContext);
  const { handleError, handleSuccess } = useNotification();
  const [allAdmins, setAllAdmins] = useState([])
  const [admins, setAdmins] = useState(null);
  

      
    const getAllAdminsList = async () => {
    const admins = await getAllAdmins()
    console.log({admins})
    setAllAdmins(admins.documents)
    
  }






  return {
      admins,
      setAdmins,
      getAllAdminsList,
      allAdmins,
      setAllAdmins,
  
  };
};

export default useAdmins;
