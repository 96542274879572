import { createContext, useState } from "react";

export const viewSearchContext = createContext()
const Provider = viewSearchContext.Provider

const ViewSearchProvider = (props)=>{
    const [activesearch,setActiveSearch] = useState(false)

    return <Provider value={{activesearch,setActiveSearch} }>
        {props.children}
    </Provider>
}

export default ViewSearchProvider;