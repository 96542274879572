import React, {useContext, useEffect, useState} from 'react'
import { DataContext } from '../../context/Data/DataContext';
import { Link } from 'react-router-dom';
//
import { BiSearchAlt } from "react-icons/bi";
import { BiEdit } from "react-icons/bi";
import { TiDeleteOutline } from "react-icons/ti";
import { FaRegCopy } from "react-icons/fa";
import { AiOutlineFolderView } from "react-icons/ai"
import useConsultations from '../../hooks/useConsultations';





const  Consultinfotable = () => {
    const { getAllconsultationsList, allconsultations  } =
    useConsultations();
  //   console.log({ allconsultations });
  const [searchResult, setSearchResult] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    const filteredPatients = allconsultations.filter(
      (consultations) =>
        consultations.patientFirstName.toLowerCase().includes(value.toLowerCase()) ||
        consultations.patientLastName.toLowerCase().includes(value.toLowerCase()) ||
        consultations.date.toLowerCase().includes(value.toLowerCase())
    );
    setSearchResult(filteredPatients);
  };
  
  useEffect(() => {
    getAllconsultationsList();
  }, []);

 

  useEffect(() => {
    console.log("all patients are => ", allconsultations);
    setSearchResult(allconsultations);
  }, [allconsultations]);

  return (
    <>
      <div className="flex justify-end px-8 md:px-2 mb-5">
        <label class="relative block mt-6 md:mt-8 w-1/2 md:w-1/2 lg:w-1/5">
          <span class="sr-only ">Search</span>
          <div className="flex">
            <div class="absolute inset-y-0 left-0 pt-3 pl-2 text-searchicon z-10">
              <BiSearchAlt className="" />
            </div>
            <input
              class=" placeholder:text-searchicon block bg-white w-full border border-slate-300 rounded-md py-2 pl-6 pr-2 shadow-sm   drop-shadow-sm placeholder-nextgray focus:outline-none focus:border-consultbg focus:ring-consultbg focus:ring-1 sm:text-sm"
              placeholder="Search"
              type="text"
              name="search"
              value={searchValue}
              onChange={handleSearchChange}
            />
          </div>
        </label>
      </div>
    <div className=" px-8 md:px-2">
    <div className="w-full h-fit border border-navgray">
            <div className="overflow-x-auto w-full">
            <table className="table w-full">
            {/* <!-- head --> */}
            <thead>
              <tr className="bg-pink-200">
                <th className="bg-pink-200">
                  <div className="bg-pink-200">#</div>
                </th>
                <th className="bg-pink-200">Patients</th>
                <th className="bg-pink-200">Diagnosis</th>
                <th className="bg-pink-200">Complaints</th>
                <th className="bg-pink-200">Date</th>
                <th className="bg-pink-200">Actions </th>
              </tr>
            </thead>
            <tbody>
              {/* <!-- row 1 --> */}
              {searchResult?.map((elem, index) => (
              <tr>
                <th>
                  <label>
                    <div>{index +1}</div>
                  </label>
                </th>
                <td>
                  <div className="flex items-center space-x-3">
                   
                    <div className="font-bold">{elem?.patientFirstName + " " + elem?.patientLastName}</div>
                  
                  </div>
                  </td>
                  
                  <th>
                  <div>
                      <div className="font-bold">{elem?.complaints}</div>
                    </div>
                  </th> 
                <th>
                  <div className="">{elem.diagnosis}</div>
                  </th>
                  <th>
                  <div className="">{elem.date}</div>
                </th>
                <td className="flex justify-around items-center text-center mt-4">
                       <Link to={`/viewconsultation?id=${elem?.$id}`}>
                        <AiOutlineFolderView size={25} className="" />
                      </Link>
                 
                  <br />
                </td>
              </tr>

             
              ))} 
            </tbody>
          </table>
            </div>
          </div>
      </div>
      </>
  )
}

export default Consultinfotable