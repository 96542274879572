import { useState, createContext } from "react";

export const UtilityContext = createContext();

export const UtilityProvider = ({ children }) => {
  const [showLoader, setShowLoader] = useState(false);

  const utilityContextValue = {
    showLoader,
    setShowLoader,
  };

  return <UtilityContext.Provider value={utilityContextValue}>{children}</UtilityContext.Provider>;
};
