import { useState, createContext, useEffect } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState({});
  const [adminDetails, setAdminDetails] = useState({});

  useEffect(() => {
    if (localStorage.getItem("doci_admin_user")) {
      const persisted = JSON.parse(localStorage.getItem("doci_admin_user"));
      setUserDetails(persisted);
    }
  }, []);

  const userContextValue = {
    userDetails,
    setUserDetails,
    adminDetails,
    setAdminDetails,
  };

  return (
    <UserContext.Provider value={userContextValue}>
      {children}
    </UserContext.Provider>
  );
};
