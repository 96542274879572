import { getAllPatients, updatePatient } from "../../utils/appwrite/appwriteConfig";

export const getPatients = () => {
  return getAllPatients().then((res) => {
    return res;
  });
};
export const updatePatientData = (id, data) => {
  return updatePatient().then(id, data).then((res) => {
    return res;
  });
};






