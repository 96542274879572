import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { generalContext } from "../../context/Form/GeneralContext";
import { IoReturnDownBackOutline } from "react-icons/io5";
import Frame from "../../assets/Photos/Frame.png";
import Logo from "../../assets/Photos/logo.png";

import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import usePatient from "../../hooks/usePatients";

const ViewPatientpage = () => {
  const [viewed, setViewed] = useState(null);
  const { getAllPatientsList, allpatients, updatePatient } = usePatient();

  // console.log({subs:allSubscriptions})

  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("id");

  // console.log({id})

  const patient = allpatients.find((plan) => plan.$id == id);
  // console.log({subplan})

  useEffect(() => {
    getAllPatientsList();
  }, []);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("Enter patient's first name"),
    last_name: Yup.string().required("Enter patient's last name"),
    dob: Yup.string().required("Enter patient's date of birth"),
    gender: Yup.string().required("Enter patient's gender"),
    blood_type: Yup.string().required("Enter patient's blood type"),
    phone_number: Yup.string().required("Enter patient's phone number"),
    email: Yup.string().required("Enter patient's email"),
    weight: Yup.string().required("Enter patient's weight"),
    height: Yup.string().required("Enter patient's height"),
    age: Yup.string().required("Please enter a designation"),

  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors} = formState;

  const submitHandler = async (data) => {
    try {
      console.log({ submitted: data });
      updatePatient(id, data).then((res) => {
        console.log(res);
        reset();
        navigate("/patients");
      });
    } catch (error) {
      console.log(error.message);

      return;
    }
  };

  const changeHandler = (e) => {
    setViewed({
      ...viewed,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className=" flex justify-center items-center pt-24 px-12 md:px-40 transparent">
      <div className="border-2 w-full h-full px-4 md:px-8 py-12 rounded-md shadow-xl relative z-50 fade-in-left ">
        {/* back */}
        <div>
          <Link to="/patients">
            <IoReturnDownBackOutline size={25} />
          </Link>
        </div>

        {/* logo */}
        <div className=" flex justify-center">
          <img src={Logo} alt="" className=" " />
        </div>

        <div className="text-center text-[24px] italic pb-8">
          <div>{(patient?.first_name || " ") + " " + (patient?.last_name  || " ")}</div>
        </div>

        <form
          className="lg:text-center"
          onSubmit={handleSubmit(submitHandler)}
        >
          
            <label style={{display: "inline-block", width: "138px"}} className="pr-4">First Name:</label>
            
              <input
                {...register("first_name")}
                name="first_name"
                value={patient?.first_name}
                className=" w-full lg:w-1/2 border-slate-300  placeholder:text-sm placeholder:text-slate-300 rounded-md focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
                type="text"
                placeholder=" "
                required="yes"
              />

          

          <div className="pt-6">
            <label style={{display: "inline-block", width: "138px"}} className="">Last Name:</label>
            
              <input
                {...register("last_name")}
                name="last_name"
                value={patient?.last_name}
                className=" w-full lg:w-1/2 border-slate-300 placeholder:text-sm placeholder:text-slate-300 rounded-md focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
                type="text"
                placeholder=""
                required="yes"
              />
           
          </div>

          <div className="pt-6">
            <label style={{display: "inline-block", width: "138px"}} className="">DOB:</label>
           
              <input
                {...register("dob")}
                value={patient?.dob}
                name="title"
                className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
                type="text"
                placeholder=""
                required="yes"
              />
           
          </div>

          <div className="pt-6">
            <label style={{display: "inline-block", width: "138px"}} className="">Gender:</label>
            
              <input
                {...register("gender")}
                value={patient?.gender}
                name="title"
                className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
                type="text"
                placeholder=""
                required="yes"
              />
            
          </div>

          <div className="pt-6">
            <label style={{display: "inline-block", width: "138px"}} className="">Blood Type:</label>
            
              <input
                {...register("blood_type")}
                value={patient?.blood_type}
                name="title"
                className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
                type="text"
                placeholder=""
                required="yes"
              />
            
          </div>


          <div className="pt-6">
            <label style={{display: "inline-block", width: "138px"}} className="">Phone Number:</label>
           
              <input
                {...register("phone_number")}
                value={patient?.phone_number}
                name="title"
                className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
                type="text"
                placeholder=""
                required="yes"
              />
           
          </div>



          <div className="pt-6">
            <label style={{display: "inline-block", width: "138px"}} className="">Email:</label>
            
              <input
                {...register("email")}
                value={patient?.email}
                name="title"
                className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
                type="text"
                placeholder=""
                required="yes"
              />
            
          </div>




          <div className="pt-6">
            <label style={{display: "inline-block", width: "138px"}} className="">Age:</label>
          
              <input
                {...register("age")}
                value={patient?.age}
                name="designation"
                className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
                type="number"
                placeholder=""
                required="yes"
              />
           
          </div>





          <div className="flex justify-center items-center cursor-pointer pt-5">
            <button 
              type="submit"
              className="bg-pink-200 px-12 py-4 rounded-md shadow-sm cursor-not-allowed"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ViewPatientpage;
