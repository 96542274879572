import { useState, useContext } from "react";
import { UtilityContext } from "../context/Utility/UtilityContext";
import {
  createNewOrganization,
  createNewOrgUserAccount,
  createOrganizationUser,
  updateOrganizationData,
} from "../services/organizations/organizations";
import useNotification from "../hooks/useNotifications";
import { getAllOrganizations } from "../utils/appwrite/appwriteConfig";
import { Client, Account, Databases, Query, ID } from "appwrite";

const client = new Client();

client
  .setEndpoint(process.env.REACT_APP_API)
  .setProject(process.env.REACT_APP_PROJECT_KEY);
const DBID = process.env.REACT_APP_APPWRITE_DATABASE_ID;
const OrganizationCollection = "638f3ce32ab7d4acdeaa";
const account = new Account(client);

const DB = new Databases(client);

const useOrganizations = () => {
  const { setShowLoader } = useContext(UtilityContext);
  const { handleError, handleSuccess } = useNotification();
  const [allOrganizations, setAllOrganizations] = useState([]);
  const location = window.location;

  let orgId;
  let orgDetails;

  const createOrganizationAccount = async (data) => {
    console.log({ data });
    setShowLoader(true);

    try {
      const result1 = await createNewOrgUserAccount({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.admin_email,
        password: data.admin_password,
      });

      if (!result1) throw new Error("failed to create new organization user");

      console.log("sucessfully created createNewOrgUserAccount");

      const result2 = await createNewOrganization({
        company_name: data.company_name,
        company_address: data.company_address,
        company_email: data.company_email,
        rc_number: data.rc_number,
      });

      if (!result2)
        throw new Error("failed to create new organization account");

      console.log("sucessfully made createNewOrganization");
      

      await createOrganizationUser({
        userID: result1.$id,
        OrgUserID: result2.$id,
      });


      orgId = result2.$id;
      orgDetails = result2;

     
      handleSuccess("Organization created successfully");
    } catch (error) {
      console.log({ error });
      handleError(error);
      return false;
    } finally {
      setShowLoader(false);
    }

    

    // Create company link in org collection
    try {
      const res = await DB.updateDocument(DBID, OrganizationCollection, orgId, {
        company_registration_link: `${
          location?.origin
        }/org/${orgDetails?.company_name?.replace(/\s+/g, "-")}-${orgId}`,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getAllOrganizationsList = async () => {
    const organizations = await getAllOrganizations();
    console.log({ organizations });
    setAllOrganizations(organizations.documents);
  };

  const updateOrganization = async (id, data) => {
    setShowLoader(true);
    console.log("udpate in useOrganization", id, data);
    try {
      const updateRes = await updateOrganizationData(id, data);
      console.log({ updateRes });
      if (!updateRes.$id) throw new Error(`Organization failed to be updated`);
      handleSuccess("Organization updated successfully");
      return true;
    } catch (error) {
      console.log({ error });
      handleError(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  return {
    allOrganizations,
    updateOrganization,
    createOrganizationAccount,
    getAllOrganizationsList,
  };
};

export default useOrganizations;
