import React from "react";
import Layout from "../component/Layout Component/Layout";
import { Subscriptionplancard } from "../component/Layout Component/Cards";
import Subplaninfotable from "../component/Layout Component/Subplanifotable";

//
//
import { BiSearchAlt } from "react-icons/bi";

const Subscription = () => {
  return (
    <Layout>
      <Subscriptionplancard />
      {/* search */}
    
      <Subplaninfotable />
    </Layout>
  );
};

export default Subscription;
