import React, { useContext, useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Client, Account, ID, Databases } from "appwrite";
import useNotification from "../hooks/useNotifications";
import "react-toastify/dist/ReactToastify.css";
import Doctorimg from "../assets/Photos/doctorimg.jpg";
import logo from "../assets/Photos/logo.png";
import { client } from "../utils/appwrite/appwriteConfig";
import { UserContext } from "../context/User/UserContext";



const PasswordRecovery = () => {
  const { handleError, handleSuccess } = useNotification();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  let userObj = useContext(UserContext);
  let location = window.location;

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required")
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const account = new Account(client);
 
  const submitForm = async (data) => {
    setLoading(true);
    console.log(data);

    try{
      const res = await account.createRecovery(data?.email, `${location.origin}/password_recovery`)
      setLoading(false)
      handleSuccess("Please check your email for the password recovery link");
    }catch(error) {
      handleError(error.message);
      setLoading(false)
     
    }

    
  };

  return (
    <div className="w-full h-full">
    <div className="flex h-full max-w-full">
      {/* left screen */}
      <div className="h-full">
        <div className="bg-mygray w-full h-full hidden md:block">
          <img src={Doctorimg} alt="" srcset="" className="h-full" />
  
          {/* logo */}
          {/* <div className="">
          <img src={Frame} alt="" srcset="" />
        </div> */}
        </div>
      </div>
  
      {/* right screen */}
      <div className="lg:1/5 sm:w-1/2 md:w-1/2 ">
        <div className="w-full h-full sm:text-center md:text-start lg:text-start  px-12 md:px-12 ">
          <div className=" flex justify-center pt-12">
            <img src={logo} alt="" srcset="" />
          </div>
  
          <div className="font-sans text-center pb-12 font-semibold text-[18px] text-[#FF0000] mx-auto sm:mx-0">
            {" "}
            DociHealthcare!{" "}
          </div>
          <div className="text-[#666666] text-[14px] mt-3 mx-auto sm:mx-0">
            Enter the email for password recovery{" "}
          </div>
          <p
            className="text-[15px] font-bold text-[#a56f6f] cursor-pointer my-2"
            onClick={() => navigate("/login")}
          >
            Back to login
          </p>
  
          {/* form */}
  
          {/* <Loginform/> */}
          {/* form */}
          <form onSubmit={handleSubmit(submitForm)}>
            <div className="mt-8">
              <p className="text-[#666666] text-[15px] font-bold">
                Email Address
              </p>
              <p className="text-[#666666] text-[14px] max-w-[350px] my-1 break-words">
                Please enter the admin email used to create this company account
              </p>
              <input
                {...register("email")}
                type="email"
                name="email"
                placeholder="someone@example.com"
                className="mt-1 mb-six block w-full h-14 rounded-md text-left pl-2 text-sm  border-bordergray border sm:text-sm"
              />
              <p className="text-[13px] mt-2 text-red-400">
                {errors.email?.message}
              </p>
            </div>
  
            <button
              type="submit"
              className=" bg-gradient-to-r from-red-500  via-red-600 to-red-700 inline-flex justify-center w-full h-12 rounded-lg text-white py-3"
            >
              Recover
            </button>
          </form>
  
          {/*  */}
        </div>
      </div>
    </div>
  </div>
  );
};

export default PasswordRecovery;
