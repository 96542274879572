import { useState, useContext } from "react";
import { UtilityContext } from "../context/Utility/UtilityContext";
import useNotification from "../hooks/useNotifications"
import { getConsultations, updateConsultationData} from "../services/consultations/consultatipns";
import { getOnePatient } from "../utils/appwrite/appwriteConfig";



const useConsultations = () => {
  const { setShowLoader } = useContext(UtilityContext);
  const { handleError, handleSuccess } = useNotification();
  const [allconsultations, setAllConsultations] = useState([])


  const getAllconsultationsList = async () => {

    const consultations = await getConsultations()
    console.log({ consultations })
    
    const modifiedConsultations = await Promise.all(consultations.documents.map(async consultation => {

      const patientInfo = await getOnePatient(consultation.patientID)
      
      return {
        ...consultation,
        patientFirstName: patientInfo.first_name,
        patientLastName: patientInfo.last_name,
        patientEmail: patientInfo.email
        
      }
    }))

    console.log({modifiedConsultations})
    setAllConsultations(modifiedConsultations)

  }

  const updateConsultation = async (id, data) => {
    setShowLoader(true);
    console.log('udpate in usePatient',id,data);
    try {
       const updateRes = await updateConsultationData(id, data)
       console.log({updateRes})
       if(!updateRes.$id) throw new Error(`patient failed to be updated`)
       handleSuccess("Patient updated successfully");
       return true
    } catch (error) {
      console.log({error})
      handleError(error);
      return false
    } finally {
      setShowLoader(false);
    }
  };

  return {
    allconsultations,
    updateConsultation,
    setAllConsultations,
    getAllconsultationsList

  };
};

export default useConsultations;





