import {  updateSubUsers, getAllSubUsers, } from "../../utils/appwrite/appwriteConfig";

export const getSubusers = () => {
  return getAllSubUsers().then((res) => {
    return res;
  });
};
export const updateSubscriptionuserData = (id,data) => {
  return updateSubUsers(id,data).then((res) => {
    return res;
  });
};



