import { useState, useContext } from "react";
import { UtilityContext } from "../context/Utility/UtilityContext";
import useNotification from "../hooks/useNotifications";
import {
  getScheduler,
  updateSchedulerData,
} from "../services/scheduler.js/schedulerapp";
import { getOneDoctor } from "../utils/appwrite/appwriteConfig";

import { Client, Account, Databases, Query, ID } from "appwrite";

const client = new Client();

client
  .setEndpoint(process.env.REACT_APP_API)
  .setProject(process.env.REACT_APP_PROJECT_KEY);
const DBID = process.env.REACT_APP_APPWRITE_DATABASE_ID;
const schedulerCollection = "6414289cbe2d598a5231";
const account = new Account(client);

const DB = new Databases(client);

const useScheduler = () => {
  const { setShowLoader } = useContext(UtilityContext);
  const { handleError, handleSuccess } = useNotification();
  const [allscheduler, setAllScheduler] = useState([]);

  const getAllSchedulerList = async () => {
    const scheduler = await getScheduler();
    console.log({ scheduler });

    const modifiedScheduler = scheduler.documents.map( (scheduler) => {
        return {
          ...scheduler,
          doctors: JSON.parse(scheduler.doctors),
        };
      })
    ;

    console.log({ modifiedScheduler });
    setAllScheduler(modifiedScheduler);
  };

  const updateScheduler = async (id, data) => {
    setShowLoader(true);
    console.log("udpate in usePatient", id, data);
    try {
      const updateRes = await updateSchedulerData(id, data);
      console.log({ updateRes });
      if (!updateRes.$id) throw new Error(`patient failed to be updated`);
      handleSuccess("Updated successfully");
      return true;
    } catch (error) {
      console.log({ error });
      handleError(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  return {
    allscheduler,
    updateScheduler,
    setAllScheduler,
    getAllSchedulerList,
  };
};

export default useScheduler;
