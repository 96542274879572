import { useContext } from "react";
import { UserContext } from "../context/User/UserContext";
import { UtilityContext } from "../context/Utility/UtilityContext";
import useNotification from "../hooks/useNotifications";
import { getAccountDetails, getAdminAccountDetails } from "../services/auth/auth";

const useUser = () => {
  const { setUserDetails, setAdminDetails} = useContext(UserContext);
  const { setShowLoader } = useContext(UtilityContext);
  const { handleError, handleSuccess } = useNotification();

  const getuserDetails = async () => {
    setShowLoader(true);
    try {
      getAccountDetails().then((res) => {
        setUserDetails(res);
        getAdminAccountDetails(res.$id)
          .then((res) => {
            setAdminDetails(res?.documents[0]);
          })
          .catch((error) => {
            handleError(error);
          });
      });
    } catch (error) {
      handleError(error);
    } finally {
      setShowLoader(false);
    }
  };

  const logOutUser = () => {
    localStorage.removeItem("doci_admin_user");
    window.location.href = "/";
  };

  return { logOutUser, setUserDetails, setShowLoader, handleError, handleSuccess, getuserDetails };
};

export default useUser;
