import { useContext, useState } from "react";
import { UtilityContext } from "../context/Utility/UtilityContext";
import useNotification from "../hooks/useNotifications";
import {
  createNewDoctor,
  createNewDoctorUserAccount,
  updateDoctorData,
} from "../services/doctors/doctors";
import { getAllDoctors } from "../utils/appwrite/appwriteConfig";

const useDoctor = () => {
  const { setShowLoader } = useContext(UtilityContext);
  const { handleError, handleSuccess } = useNotification();
  const [allDoctors, setAllDoctors] = useState([]);

  const createDoctorAccount = async (data) => {
    setShowLoader(true);
    console.log(data);
    try {
      const acctRes = await createNewDoctorUserAccount(
        data.email,
        data.password,
        data.firstname,
        data.lastname
      );
      console.log({ acctRes });
      if (!acctRes.$id) throw new Error(`account failed to be created`);
      const createRes = await createNewDoctor({
        userID: acctRes.$id,
        first_name: data.firstname,
        last_name: data.lastname,
        designation: data.designation,
        // specialization:data.specialization,
        email: data.email,
        // organization: data.organization
      });
      console.log({ createRes });
      handleSuccess("Doctor account created successfully");
      return true;
    } catch (error) {
      console.log({ error });
      handleError(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  const getAllDoctorsList = async () => {
    const doctors = await getAllDoctors();
    console.log({ doctors });
    setAllDoctors(doctors.documents);
  };

  const updateDoctor = async (id, data) => {
    setShowLoader(true);
    console.log("udpate in usePatient", id, data);
    try {
      const updateRes = await updateDoctorData(id, data);
      console.log({ updateRes });
      if (!updateRes.$id) throw new Error(`Doctor failed to be updated`);
      handleSuccess("Doctor updated successfully");
      return true;
    } catch (error) {
      console.log({ error });
      handleError(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  return {
    allDoctors,
    updateDoctor,
    createDoctorAccount,
    getAllDoctorsList,
  };
};

export default useDoctor;
