import React from "react";
import Layout from "../component/Layout Component/Layout";
import { Subscriptioncard } from "../component/Layout Component/Cards";
import Subinfotable from "../component/Layout Component/Subinfotable";

//
//
import { BiSearchAlt } from "react-icons/bi";

const Subscription = () => {
  return (
    <Layout>
      <Subscriptioncard />
      {/* search */}
    
      <Subinfotable />
    </Layout>
  );
};

export default Subscription;
