import {getAllHealthtips, updateHealthtip} from "../../utils/appwrite/appwriteConfig";

export const getHealthtips = () => {
  return getAllHealthtips().then((res) => {
    return res;
  });
};
export const updateHealthtipData = (id,data) => {
  return updateHealthtip(id,data).then((res) => {
    return res;
  });
};



