import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { generalContext } from "../../context/Form/GeneralContext";
import { IoReturnDownBackOutline } from "react-icons/io5";
import Frame from "../../assets/Photos/Frame.png";
import Logo from "../../assets/Photos/logo.png"



import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useHealthtips from "../../hooks/useHealthtips";
import { getAllHealthtips } from "../../utils/appwrite/appwriteConfig";


const EditHealthtipspage = () => {

  const [editedTips, setEditedTips] = useState(null)
  const {  updateHealthtips, allHealthtips, getAllHealhtipsList } = useHealthtips();
  
  // console.log({subs:allSubscriptions})
  
  const navigate = useNavigate();
  const location = useLocation();
  
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get('id');
  
  // console.log({id})
  
  const healthtips = allHealthtips.find(tips=>tips.$id===id)
  // console.log({subplan})
  
  
  useEffect(() => {

    getAllHealhtipsList();


 }, []);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Update infromation"),
    image: Yup.string().required("Update infromation"),
    date: Yup.string().required("Update infromation"),
   description: Yup.string().required("Update infromation"),
    tags: Yup.string().required("Update infromation"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;




  const submitHandler = async (data) => {
    try {
    console.log({submitted:data});
      updateHealthtips(id, data).then((res) => {
        console.log(res);
        reset();
        navigate("/healthtip");
      });
    } catch (error) {
      console.log(error.message);

      return;
    }
  };

  const changeHandler = (e) => {
    setEditedTips({
      ...editedTips,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className=" flex justify-center items-center pt-24 px-12 md:px-40 bg-pink-100/30 ">
      <div className="border-2  w-full h-full px-4 md:px-8 py-12 rounded-md shadow-xl relative z-50 ">
        {/* back */}
        <div>
          <Link to="/healthtip">
            <IoReturnDownBackOutline size={25} />
          </Link>
        </div>

        {/* logo */}
        <div className="flex justify-center">
          <img src={Logo} alt="" className="" />
        </div>

       <div className="text-center text-[24px] italic pb-8">
       <div>{healthtips?.title}</div>
       </div>

        <form className=" lg:text-center" onSubmit={handleSubmit(submitHandler)}>

          <div>
            <label style={{display: "inline-block", width: "138px"}} className="">Title:</label>
            
            <input
            {...register('title')}
              name="title"
              defaultValue={healthtips?.title}
              className=" w-full lg:w-1/2 border-slate-300  placeholder:text-sm placeholder:text-slate-300 rounded-md focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
              type="text"
              placeholder=" Update Title"
              required="yes"
            />
            
          </div>

          <div className="pt-6">
            <label  style={{display: "inline-block", width: "138px"}}className="">Image:</label>
         
           <input
            {...register('image')}
            name="image"
            defaultValue={healthtips?.image}
              className=" w-full lg:w-1/2 border-slate-300 placeholder:text-sm placeholder:text-slate-300 rounded-md focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
              type="text"
              placeholder="Update Image"
              required="yes"
            />
           
          </div>

          <div className="pt-6">
            <label style={{display: "inline-block", width: "138px"}} className="">Date:</label>
           
           <input
            {...register('date')}
            defaultValue={healthtips?.date}
            name="date"
              className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
              type="text"
              placeholder="Update Date"
              required="yes"
            />
          
          </div>

          <div className="pt-6">
           <label style={{display: "inline-block", width: "138px"}} className="">Description:</label>
           
            <textarea
              {...register('description')}
              defaultValue={healthtips?.description}
              name="description"
              className="textarea textarea-bordered  w-full lg:w-1/2  rounded-md placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300"
              placeholder="Update description"
              required="yes"
            ></textarea>
           
          </div>

          <div className="pt-6">
            <label style={{display: "inline-block", width: "138px"}} className="">Tags:</label>
           
           <textarea
            {...register('tags')}
            defaultValue={healthtips?.tags}
            name="tags"
              className="textarea textarea-bordered w-full lg:w-1/2 rounded-md placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300"
              placeholder="Update Tags"
              required="yes"
            ></textarea>
         
          </div>

          <div className="flex justify-center items-center cursor-pointer pt-5">
                <button
                  type="submit"
                  className="bg-pink-200 italic px-12 py-4 rounded-md shadow-sm"
                >
                  Update
                </button>
              </div>
        </form>
      </div>
    </div>
  );
};

export default EditHealthtipspage;
