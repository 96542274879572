import React, {useState, useContext, useEffect} from 'react'
import Layout from "../component/Layout Component/Layout"
import { Link, useNavigate } from 'react-router-dom';

import { Doctorcard } from '../component/Layout Component/Cards';
import Doctorsinfotable from '../component/Layout Component/Doctorsinfotable';
import useDoctor from '../hooks/useDoctors';

//
import { BiSearchAlt } from "react-icons/bi";
import { BiEdit } from "react-icons/bi";
import { TiDeleteOutline } from "react-icons/ti";
import { FaRegCopy } from "react-icons/fa";
import {AiOutlineFolderView } from "react-icons/ai"
import ReactImageFallback from "react-image-fallback";
import defaultImage from '../assets/Photos/default_user.png'


//
import Doctorform from '../component/Form Component/Doctorform';

import { DataContext } from '../context/Data/DataContext';


const AdminDoctors = () => {
  const { getAllDoctorsList, allDoctors } = useDoctor();
   
  const navigate = useNavigate();

  const [searchResult, setSearchResult] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    const filteredDoctors = allDoctors.filter(
      (doctor) =>
        doctor.first_name.toLowerCase().includes(value.toLowerCase()) ||
        doctor.last_name.toLowerCase().includes(value.toLowerCase()) ||
        doctor.email.toLowerCase().includes(value.toLowerCase())
    );
    setSearchResult(filteredDoctors);
  };



 useEffect(() => {
  getAllDoctorsList();

 }, []);
  
  
 useEffect(() => {
  console.log("all patients are => ", allDoctors);
  setSearchResult(allDoctors);
}, [allDoctors]);

  return (
   <Layout>
      <Doctorcard/>
       {/* search */}
       <div className="flex justify-end px-8 md:px-2 mb-5">
            <label class="relative block mt-6 md:mt-8 w-1/2 md:w-1/2 lg:w-1/5">
              <span class="sr-only ">Search</span>
              <div className="flex">
                <div class="absolute inset-y-0 left-0 pt-3 pl-2 text-searchicon z-10">
                  <BiSearchAlt className=""/>
                </div>
                <input

                  class=" placeholder:text-searchicon block bg-white w-full border border-slate-300 rounded-md py-2 pl-6 pr-2 shadow-sm   drop-shadow-sm placeholder-nextgray focus:outline-none focus:border-consultbg focus:ring-consultbg focus:ring-1 sm:text-sm"
                  placeholder="Search"
                  type="text"
              name="search"
              value={searchValue}
              onChange={handleSearchChange}
                />
              </div>
            </label>
                
            </div>
           {/* doctors form */}
           <div className=" px-8 md:px-2">
      <div className="w-full h-fit border border-navgray">
        <div className="overflow-x-auto w-full">
          <table className="table w-full">
            {/* <!-- head --> */}
            <thead>
              <tr className="bg-pink-200">
                <th className="bg-pink-200">
                  <div className="bg-pink-200">#</div>
                </th>
                <th className="bg-pink-200">Doctors</th>
                <th className="bg-pink-200">Email</th>
                <th className="bg-pink-200">Designation</th>
                <th className="bg-pink-200">Actions</th>
              </tr>
            </thead>
            <tbody>
              {/* <!-- row 1 --> */}
                {searchResult?.map((elem, index) => {
                  let userImage =
                    elem?.image ||
                    `https://ui-avatars.com/api/?name=${elem?.first_name + " " + elem?.last_name
                    }&background=B21E14&color=fff`;
                  return (
                    <tr>
                      <th>
                        <label>
                          <div>{index + 1}</div>
                        </label>
                      </th>
                      <td>
                        <div className="flex items-center space-x-3">
                          {/* <div className="avatar">
                            <div className="mask mask-squircle w-12 h-12">
                              <ReactImageFallback
                                src={elem?.image}
                                fallbackImage={defaultImage}
                                initialImage={defaultImage}
                                alt=""
                              />
                            </div>
                          </div> */}
                           <div className='avatar'>
                          <div className='mask mask-squircle w-12 h-12'>
                            <img src={userImage} alt='patient_image' />
                          </div>
                        </div>
                          <div>
                            <div className="font-bold">{elem?.first_name + " " + elem?.last_name}</div>
                          </div>
                        </div>
                      </td>
                      <th>
                        <div className="btn btn-ghost btn-xs">{elem.email}</div>
                      </th>
                      <td>{elem.designation}</td>

                      <td className="flex justify-around items-center text-center mt-4">
                        <Link to={`/viewdoctor?id=${elem?.$id}`}>
                          <AiOutlineFolderView size={25} className="" />
                        </Link>
                        {/* <FaRegCopy size={20}/> */}
                        <br />
                      </td>
                    </tr>
                  )
             
                })} 
            </tbody>
          </table>
        </div>
      </div>
    </div>
     

   </Layout>
   
     



  )
}

export default AdminDoctors