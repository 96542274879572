import { useState, useContext } from "react";
import { UtilityContext } from "../context/Utility/UtilityContext";
import useNotification from "../hooks/useNotifications"
import { getPatients, updatePatientData } from "../services/patients/patients";



const usePatient = () => {
  const { setShowLoader } = useContext(UtilityContext);
  const { handleError, handleSuccess } = useNotification();
  const [allpatients, setAllPatients] = useState([])
  const [loading, setLoading] = useState(false)

  const updatePatient = async (id, data) => {
    setShowLoader(true);
    console.log('udpate in usePatient',id,data);
    try {
       const updateRes = await updatePatientData(id, data)
       console.log({updateRes})
       if(!updateRes.$id) throw new Error(`patient failed to be updated`)
       handleSuccess("Patient updated successfully");
       return true
    } catch (error) {
      console.log({error})
      handleError(error);
      return false
    } finally {
      setShowLoader(false);
    }
  };

  const getAllPatientsList = async () => {
    setShowLoader(true);
    const patients = await getPatients()
    console.log({patients})
    setAllPatients(patients.documents)
    setShowLoader(false);
  }

  return {
    allpatients,
    getAllPatientsList,
    updatePatient,
  };
};

export default usePatient;





