import React, { useState, useContext, useEffect } from "react";
import { Link, Routes, Route, useNavigate } from "react-router-dom";
import { BsArrowUp, BsArrowDown } from "react-icons/bs";

//
import useDoctor from "../../hooks/useDoctors";
import usePatient from "../../hooks/usePatients";
import useOrganizations from "../../hooks/useOrganizations";
import useSubcription from "../../hooks/useSubscription";
import useConsultations from "../../hooks/useConsultations";

//
import { generalContext } from "../../context/Form/GeneralContext";
import { DataContext } from "../../context/Data/DataContext";

//
import Doctorform from "../Form Component/Doctorform";
import useSubcriptionuser from "../../hooks/useSubscriptionusers";
import useHealthtips from "../../hooks/useHealthtips";

export const Patientcard = () => {
  const { getAllPatientsList, allpatients, updatePatient } = usePatient();
  
  console.log({ allpatients });

  useEffect(() => {
    getAllPatientsList();
  }, []);

  return (
    <div>
      <div className="mt-8   grid-none cols-none md:grid gap-4 grid-cols-2  px-8 md:px-0  w-full mb-12">
        {/* welcome back */}
        <h1 className="text-4xl font-medium italic text-grayon items-center justify-center mt-12">
          Welcome Back!
        </h1>

        {/* total patients */}
        <div className="   border border-navgray shadow-md  rounded-lg ">
           <div className="flex justify-end mt-5 mr-3">
            <div className="text-center w-20 rounded-l-full rounded-r-full bg-greentextbg h-6 items-center border-5">
              {/* <BsArrowUp className=" text-greentext ml-6 font-bold" /> */}
              <div className="text-greentext font-medium text-[18px]">{allpatients?.length}</div>
            </div>
          </div> 
          <div classsName="">
            <div className=" font-bold text-lg mt-4 px-3">
           Subscriptions
            </div>
            <div className="mt-3 px-3 text-sm mb-6 md:mb-8 lg:mb-6 text-cardtextcl">
            Total Number of Patients
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Doctorcard = (props) => {
  const { getAllDoctorsList, allDoctors } = useDoctor();

  const navigate = useNavigate();

  useEffect(() => {
    getAllDoctorsList();
  }, []);

  return (
    <div>
      <div className="mt-8   grid-none cols-none md:grid gap-4 grid-cols-2  px-8 md:px-0  w-full mb-12">
        {/* Add a new doctor */}
        <div className="mt-2">
          <div className="text-nextgray text-3xl font-bold mb-2">
            <div className="flex">
              <div>Add A New Doctor</div>
            </div>
          </div>

          <div>
            <div className="text-grayon">
              Click below to fill in informations of doctors
            </div>
          </div>

          <div className="pb-12 md:pb-0">
            <button className=" mt-4 w-1/2  lg:w-3/2 text-sm gap-2 h-8 md:h-10 border border-red-500 text-nextgray bg-white hover:bg:white rounded-md">
              <div className="text-red-500 items-center justify-center">
                <Link to="/Doctorform">
                  <div
                  // <div onClick={() => setActive(true)} */}
                  //  className="font-bold" onClick={() => props.setDoctorForm(true)} trigger
                  >
                    Add Doctor
                  </div>
                </Link>
              </div>
            </button>
          </div>
        </div>

        {/*  */}
        {/* total doctors */}
        <div className="   border border-navgray shadow-md  rounded-lg ">
           <div className="flex justify-end mt-5 mr-3">
            <div className="text-center w-20 rounded-l-full rounded-r-full bg-greentextbg h-6 items-center border-5">
              {/* <BsArrowUp className=" text-greentext ml-6 font-bold" /> */}
              <div className="text-greentext font-medium text-[18px]">{allDoctors?.length}</div>
            </div>
          </div> 
          <div classsName="">
            <div className=" font-bold text-lg mt-4 px-3">
           Subscriptions
            </div>
            <div className="mt-3 px-3 text-sm mb-6 md:mb-8 lg:mb-6 text-cardtextcl">
            Total Number of Registered Doctors
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export const Doctorappointment = (props) => {
  const { getAllDoctorsList, allDoctors } = useDoctor();

  const navigate = useNavigate();

  useEffect(() => {
    getAllDoctorsList();
  }, []);

  return (
    <div>
      <div className="mt-8   grid-none cols-none md:grid gap-4 grid-cols-2  px-8 md:px-0  w-full mb-12">
        {/* Add a new doctor */}
        <div className="mt-2">
          <div className="text-nextgray text-3xl font-bold mb-2">
            <div className="flex">
              <div>Appoint A Doctor</div>
            </div>
          </div>

          <div>
            <div className="text-grayon">
              Fill in informations below to appoint doctors
            </div>
          </div>

          {/* <div className="pb-12 md:pb-0">
            <button className=" mt-4 w-1/2  lg:w-3/2 text-sm gap-2 h-8 md:h-10 border border-red-500 text-nextgray bg-white hover:bg:white rounded-md">
              <div className="text-red-500 items-center justify-center">
                <Link to="/Doctorform">
                  <div
                 
                  >
                    Add Doctor
                  </div>
                </Link>
              </div>
            </button>
          </div> */}
        </div>

        {/*  */}
        {/* total doctors */}
        {/* <div className="   border border-navgray shadow-md  rounded-lg ">
           <div className="flex justify-end mt-5 mr-3">
            <div className="text-center w-20 rounded-l-full rounded-r-full bg-greentextbg h-6 items-center border-5">
            
              <div className="text-greentext font-medium text-[18px]">{allDoctors?.length}</div>
            </div>
          </div> 
          <div classsName="">
            <div className=" font-bold text-lg mt-4 px-3">
           Subscriptions
            </div>
            <div className="mt-3 px-3 text-sm mb-6 md:mb-8 lg:mb-6 text-cardtextcl">
            Total Number of Registered Doctors
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export const Consultationcard = () => {
  const { getAllconsultationsList, allconsultations } = useConsultations();
  
  console.log({ allconsultations });

  useEffect(() => {
    getAllconsultationsList();
  }, [])
  return (
    <div>
      <div className="mt-8   grid-none cols-none md:grid gap-4 grid-cols-2  px-8 md:px-0  w-full mb-12">
        {/* welcome back */}
        <h1 className="text-4xl font-medium italic text-grayon items-center justify-center mt-12">
          Welcome Back!
        </h1>
        {/* total consultations */}
        <div className="   border border-navgray shadow-md  rounded-lg ">
           <div className="flex justify-end mt-5 mr-3">
            <div className="text-center w-20 rounded-l-full rounded-r-full bg-greentextbg h-6 items-center border-5">
              {/* <BsArrowUp className=" text-greentext ml-6 font-bold" /> */}
              <div className="text-greentext font-medium text-[18px]">{allconsultations?.length}</div>
            </div>
          </div> 
          <div classsName="">
            <div className=" font-bold text-lg mt-4 px-3">
           Subscriptions
            </div>
            <div className="mt-3 px-3 text-sm mb-6 md:mb-8 lg:mb-6 text-cardtextcl">
            Total Number of Subscriptions
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Orgcard = () => {
  const { active, setActive } = useContext(generalContext);

  const { getAllOrganizationsList, allOrganizations } = useOrganizations();

  const navigate = useNavigate();

  useEffect(() => {
    getAllOrganizationsList();
  }, []);

  return (
    <div>
      <div className="mt-8   grid-none cols-none md:grid gap-4 grid-cols-2  px-8 md:px-0  w-full mb-12">
        {/* Add a new doctor */}
        <div className="mt-2">
          <div className="text-nextgray text-3xl font-bold mb-2">
            <div className="flex">
              <div>Add A New Organization</div>
            </div>
          </div>

          <div>
            <div className="text-grayon">
              Click below to fill in organization's information.
            </div>
          </div>

          <div className="pb-12 md:pb-0">
            <button className="mt-4 w-1/2  lg:w-3/2 text-sm gap-2 h-8 md:h-10 border border-red-500 text-nextgray bg-white hover:bg:white rounded-md">
              <div
                onClick={() => setActive("Orgform")}
                className="text-red-500 items-center justify-center "
              >
                <Link to="/Orgform">Register</Link>
              </div>
            </button>
          </div>
        </div>

        {/* total organizations */}
        <div className="   border border-navgray shadow-md  rounded-lg ">
           <div className="flex justify-end mt-5 mr-3">
            <div className="text-center w-20 rounded-l-full rounded-r-full bg-greentextbg h-6 items-center border-5">
              {/* <BsArrowUp className=" text-greentext ml-6 font-bold" /> */}
              <div className="text-greentext font-medium text-[18px]">{allOrganizations?.length}</div>
            </div>
          </div> 
          <div classsName="">
            <div className=" font-bold text-lg mt-4 px-3">
           Subscriptions
            </div>
            <div className="mt-3 px-3 text-sm mb-6 md:mb-8 lg:mb-6 text-cardtextcl">
            Total Number of Registered Organizations
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Subscriptioncard = () => {

  const {allSubscriptionsuser,getAllSubscriptionsuserList} = useSubcriptionuser()
  useEffect(()=>{
    getAllSubscriptionsuserList()
  },[])
  return (
    <div>
      <div className="mt-8   grid-none cols-none md:grid gap-4 grid-cols-2  px-8 md:px-0  w-full mb-12">
        {/* welcome back */}
        <h1 className="text-4xl font-medium italic text-grayon items-center justify-center mt-12">
          Welcome Back!
        </h1>
        {/* total organizations */}
        <div className="   border border-navgray shadow-md  rounded-lg ">
           <div className="flex justify-end mt-5 mr-3">
            <div className="text-center w-20 rounded-l-full rounded-r-full bg-greentextbg h-6 items-center border-5">
              {/* <BsArrowUp className=" text-greentext ml-6 font-bold" /> */}
              <div className="text-greentext font-medium text-[18px]">{allSubscriptionsuser?.length}</div>
            </div>
          </div> 
          <div classsName="">
            <div className=" font-bold text-lg mt-4 px-3">
           Subscriptions
            </div>
            <div className="mt-3 px-3 text-sm mb-6 md:mb-8 lg:mb-6 text-cardtextcl">
            Total Number of Subscriptions
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Subscriptionplancard = () => {
  const { getAllSubscriptionsList, allSubscriptions, updateSubcription } =
    useSubcription();
  console.log({ allSubscriptions });

  useEffect(() => {
    getAllSubscriptionsList();
  }, []);
  return (
    <div>
      <div className="mt-8   grid-none cols-none md:grid gap-4 grid-cols-2  px-8 md:px-0  w-full mb-12">
        {/* welcome back */}
        <h1 className="text-4xl font-medium italic text-grayon items-center justify-center mt-12">
          Welcome Back!
        </h1>
        {/* total organizations */}
        <div className="   border border-navgray shadow-md  rounded-lg ">
           <div className="flex justify-end mt-5 mr-3">
            <div className="text-center w-20 rounded-l-full rounded-r-full bg-greentextbg h-6 items-center border-5">
              {/* <BsArrowUp className=" text-greentext ml-6 font-bold" /> */}
              <div className="text-greentext font-medium text-[18px]">{allSubscriptions?.length}</div>
            </div>
          </div> 
          <div classsName="">
            <div className=" font-bold text-lg mt-4 px-3">
           Subscriptions
            </div>
            <div className="mt-3 px-3 text-sm mb-6 md:mb-8 lg:mb-6 text-cardtextcl">
            Total Number of Subscription Plan
            </div>
          </div>
        </div>
       
      </div>
    </div>
  );
};




export const Healthtipscard = () => {
  const { getAllHealhtipsList, allHealthtips, updateHealthtips } =
    useHealthtips();
  console.log({ allHealthtips });

  useEffect(() => {
    getAllHealhtipsList();
  }, []);
  return (
    <div>
      <div className="mt-8   grid-none cols-none md:grid gap-4 grid-cols-2  px-8 md:px-0  w-full mb-12">
        {/* welcome back */}
        <h1 className="text-4xl font-medium italic text-grayon items-center justify-center mt-12">
          Welcome Back!
        </h1>
        {/* total health tips */}
        <div className="   border border-navgray shadow-md  rounded-lg ">
           <div className="flex justify-end mt-5 mr-3">
            <div className="text-center w-20 rounded-l-full rounded-r-full bg-greentextbg h-6 items-center border-5">
              {/* <BsArrowUp className=" text-greentext ml-6 font-bold" /> */}
              <div className="text-greentext font-medium text-[18px]">{allHealthtips?.length}</div>
            </div>
          </div> 
          <div classsName="">
            <div className=" font-bold text-lg mt-4 px-3">
            Health Tips
            </div>
            <div className="mt-3 px-3 text-sm mb-6 md:mb-8 lg:mb-6 text-cardtextcl">
            Total Number of HealthTips
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};