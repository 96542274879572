import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";

//
import Login from "./pages/Login";
import Forgetpassword from "./pages/PasswordRecovery";
import PasswordRecoveryConfirmation from "./pages/PasswordRecoveryConfirmation"
import AdminConsult from "./pages/AdminConsult";
import AdminDoctors from "./pages/AdminDoctors";
import AdminOrg from "./pages/AdminOrg";
import AdminPatients from "./pages/AdminPatients";
import Settings from "./pages/Settings";
import Subscription from "./pages/Subscription";
import Doctorform from "./component/Form Component/Doctorform";
import Orgform from "./component/Form Component/Orgform";
import Subplan from "./pages/Subplan";
import EditSubscriptionpage from "./component/Form Component/EditSubscriptionpage";
import ViewPatientpage from "./component/Form Component/ViewPatientpage";
import ViewDoctorpage from "./component/Form Component/ViewDoctorpage";
import ViewOrganizationpage from "./component/Form Component/ViewOrganizationpage";
import ViewSubscriptionpage from "./component/Form Component/ViewSubscriptionpage";
import Healthtips from "./pages/Healthtips";
import Schedular from "./pages/Schedular";
// import viewConsultation from "./component/Form Component/ViewConsultation"


//
import { UtilityContext } from "./Utility/UtilityContext";
//
import ViewSearchProvider from "./context/Form/ViewSearchContext";
import GeneralProvider from "./context/Form/GeneralContext";
import ShowProvider from "./context/Form/ShowContext";
import { DataProvider } from "./context/Data/DataContext";

import { MantineProvider, LoadingOverlay } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import EditHealthtipspage from "./component/Form Component/Edithealthtipspage";
import ViewConsultation from "./component/Form Component/ViewConsultation";
import { useEffect } from "react";
import { UserContext } from "./context/User/UserContext";
import LoggedInContainer from "./component/LoggedIn";

function App() {
  const token = localStorage.getItem("doci_admin_user");
  // const { showLoader } = useContext(UtilityContext);
  // const {user} = useContext(UserContext)

  // useEffect(() => {
  //   console.log({user})
  // },[])

  return (
    <>
        <MantineProvider
        // theme={{
        //   defaultRadius: 5,
        //   colors: {
        //     "crm-blue": ["#E7F5FF", "#D0EBFF", "#A5D8FF", "#74C0FC", "#4DABF7", "#339AF0", "#0177B7", "#1C7ED6", "#1971C2", "#1864AB"],
        //   },
        //   primaryColor: "crm-blue",
        // }}
      >
        {/* <LoadingOverlay visible={showLoader}/> */}

        <ShowProvider>
          <GeneralProvider>
            <ViewSearchProvider>
              <NotificationsProvider position='top-right' zIndex={2077} autoClose={3000}>
                <Routes>
                <Route path='/forgot-password' element={<Forgetpassword/>} />
                  <Route path='/password_recovery' element={<PasswordRecoveryConfirmation/>} />
                  <Route path='/login' element={<Login />} />
                  <Route path='/*' element={token ? <LoggedInContainer /> : <Login />} /> 
                </Routes>
              </NotificationsProvider>
            </ViewSearchProvider>
          </GeneralProvider>
        </ShowProvider>
      </MantineProvider>
    </>
  );
}

export default App;
