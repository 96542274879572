import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../context/Data/DataContext";
import usePatient from "../../hooks/usePatients";
import { Link } from "react-router-dom";

//
import { BiSearchAlt } from "react-icons/bi";
import { BiEdit } from "react-icons/bi";
import { TiDeleteOutline } from "react-icons/ti";
import { FaRegCopy } from "react-icons/fa";
import { AiOutlineFolderView } from "react-icons/ai";
import ReactImageFallback from "react-image-fallback";
import defaultImage from "../../assets/Photos/default_user.png";
import { BiChevronsLeft, BiChevronsRight } from "react-icons/bi";

const Patientinfotable = () => {
  const { getAllPatientsList, allpatients, updatePatient } = usePatient();
  const [loading, setloading] = useState(false);
  console.log({ allpatients });
  const [searchResult, setSearchResult] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    const filteredPatients = allpatients.filter(
      (patient) =>
        patient.first_name.toLowerCase().includes(value.toLowerCase()) ||
        patient.last_name.toLowerCase().includes(value.toLowerCase()) ||
        patient.email.toLowerCase().includes(value.toLowerCase())
    );
    setSearchResult(filteredPatients);
  };

  useEffect(() => {
    getAllPatientsList();
  }, []);

  useEffect(() => {
    console.log("all patients are => ", allpatients);
    setSearchResult(allpatients);
  }, [allpatients]);

  return (
    <>
      <div className="flex justify-end px-8 md:px-2 mb-5">
        <label class="relative block mt-6 md:mt-8 w-1/2 md:w-1/2 lg:w-1/5">
          <span class="sr-only ">Search</span>
          <div className="flex">
            <div class="absolute inset-y-0 left-0 pt-3 pl-2 text-searchicon z-10">
              <BiSearchAlt className="" />
            </div>
            <input
              class=" placeholder:text-searchicon block bg-white w-full border border-slate-300 rounded-md py-2 pl-6 pr-2 shadow-sm   drop-shadow-sm placeholder-nextgray focus:outline-none focus:border-consultbg focus:ring-consultbg focus:ring-1 sm:text-sm"
              placeholder="Search"
              type="text"
              name="search"
              value={searchValue}
              onChange={handleSearchChange}
            />
          </div>
        </label>
      </div>
      <div className=" px-8 md:px-2">
        <div className="w-full h-fit border border-navgray">
          <div className="overflow-x-auto w-full">
            <table className="table w-full">
              {/* <!-- head --> */}
              <thead>
                <tr className="bg-pink-200">
                  <th className="bg-pink-200">
                    <div className="bg-pink-200">#</div>
                  </th>
                  <th className="bg-pink-200">Patients</th>
                  <th className="bg-pink-200">Email</th>
                  <th className="bg-pink-200">DOB</th>
                  <th className="bg-pink-200">Actions </th>
                </tr>
              </thead>
              <tbody>
                {/* <!-- row 1 --> */}
                {searchResult?.map((elem, index) => {
                  let userImage =
                    elem?.image ||
                    `https://ui-avatars.com/api/?name=${
                      elem?.first_name + " " + elem?.last_name
                    }&background=B21E14&color=fff`;
                  return (
                    <tr>
                      <th>
                        <label>
                          <div>{index + 1}</div>
                        </label>
                      </th>

                      <td>
                        <div className="flex items-center space-x-3">
                          <div className="avatar">
                            <div className="mask mask-squircle w-12 h-12">
                              <img src={userImage} alt="patient_image"/>
                            </div>
                          </div>
                          <div>
                            <div className="font-bold">
                              {elem?.first_name + " " + elem?.last_name}
                            </div>
                          </div>
                        </div>
                      </td>
                      <th>
                        <div className="btn btn-ghost btn-xs">
                          {elem?.email}
                        </div>
                      </th>
                      <td>{elem?.dob}</td>

                      <td className="flex justify-around items-center text-center mt-4">
                        <Link to={`/viewpatient?id=${elem?.$id}`}>
                          <AiOutlineFolderView size={25} className=""/>
                        </Link>
                        {/* <FaRegCopy size={20}/> */}
                        <br />
                      </td>
                    </tr>
                  );
                })}

                {loading && (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>



       {/* Pagination section */}
       <div className="py-2 min-w-[1050px] xl:w-full xl:min-w-full flex items-center gap-x-4 ">
          <button
            className="w-[30px] h-[30px] rounded-full bg-red-400 centerFlex cursor-pointer"
            onClick={() => {
             
            }}
          >
            <BiChevronsLeft size={20}/>
          </button>
          
          <input
            type="number"
            className="inputField w-[110px] h-[35px] cursor-pointer flex justify-center text-center"
            // value={number}
            onChange={(e) => {
              
              }
            }
          />
          <button
            className="w-[30px] h-[30px] rounded-full bg-red-300 centerFlex cursor-pointer"
            onClick={() => {
             
            }}
          >
            <BiChevronsRight size={20} />
          </button>
        </div>
    </>
  );
};

export default Patientinfotable;
