import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { generalContext } from "../../context/Form/GeneralContext";
import { IoReturnDownBackOutline } from "react-icons/io5";
import Frame from "../../assets/Photos/Frame.png";
import Logo from "../../assets/Photos/logo.png";

import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useDoctor from "../../hooks/useDoctors";

const ViewDoctorpage = () => {
  const [viewedDoctor, setViewedDoctor] = useState(null);
  const { getAllDoctorsList, allDoctors, updateDoctor } = useDoctor();

  // console.log({subs:allSubscriptions})

  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("id");

  // console.log({id})

  const doctor = allDoctors.find((plan) => plan.$id == id);
  // console.log({subplan})

  useEffect(() => {
    getAllDoctorsList();
  }, []);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(""),
    last_name: Yup.string().required(""),
    email: Yup.string().required(""),
    designation: Yup.string().required(""),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const submitHandler = async (data) => {
    try {
      console.log({ submitted: data });
      updateDoctor(id, data).then((res) => {
        console.log(res);
        reset();
        navigate("/doctors");
      });
    } catch (error) {
      console.log(error.message);

      return;
    }
  };

  const changeHandler = (e) => {
    setViewedDoctor({
      ...viewedDoctor,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="bg-pink-100/30 h-screen">
      <div className=" flex justify-center items-center pt-24 px-12 md:px-40  ">
        <div className="border-2  w-full h-full px-4 md:px-8 py-12 rounded-md shadow-xl relative z-50 fade-in-left">
          {/* back */}
          <div>
            <Link to="/doctors">
              <IoReturnDownBackOutline size={25} />
            </Link>
          </div>

          {/* logo */}
          <div className="flex justify-center">
            <img src={Logo} alt="" className="" />
          </div>

          <div className="text-center text-[24px] italic pb-8">
            <div>
              {" "}
              {(doctor ? "Dr. " : " ") +
                (doctor?.first_name || " ") +
                " " +
                (doctor?.last_name || " ")}
            </div>
          </div>

          <form
            className=" lg:text-center"
            onSubmit={handleSubmit(submitHandler)}
          >
            <div>
              <label
                style={{ display: "inline-block", width: "110px" }}
                className="pr-4"
              >
                First Name:
              </label>

              <input
                {...register("first_name")}
                name="first_name"
                value={doctor?.first_name}
                className=" w-full lg:w-1/2 border-slate-300  placeholder:text-sm placeholder:text-slate-300 rounded-md focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
                type="text"
                placeholder=" "
                required="yes"
              />
            </div>

            <div className="pt-6">
              <label
                style={{ display: "inline-block", width: "110px" }}
                className=""
              >
                Last Name:
              </label>

              <input
                {...register("last_name")}
                name="last_name"
                value={doctor?.last_name}
                className=" w-full lg:w-1/2 border-slate-300 placeholder:text-sm placeholder:text-slate-300 rounded-md focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
                type="text"
                placeholder=""
                required="yes"
              />
            </div>

            <div className="pt-6">
              <label
                style={{ display: "inline-block", width: "110px" }}
                className=""
              >
                Email:
              </label>

              <input
                {...register("email")}
                value={doctor?.email}
                name="title"
                className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
                type="text"
                placeholder=""
                required="yes"
              />
            </div>

            <div className="pt-6">
              <label
                style={{ display: "inline-block", width: "110px" }}
                className=""
              >
                Designation:
              </label>

              <input
                {...register("designation")}
                value={doctor?.designation}
                name="designation"
                className="w-full lg:w-1/2 border-slate-300 rounded-md placeholder:text-sm placeholder:text-slate-300 focus:outline-none focus:border-slate-300 focus:ring-slate-300  border-slate-300`"
                type="text"
                placeholder=""
                required="yes"
              />
            </div>

            <div className="flex justify-center items-center cursor-pointer pt-5">
              <button
                type="submit"
                className="bg-pink-200 italic px-12 py-4 rounded-md shadow-sm cursor-not-allowed"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ViewDoctorpage;
