import { getAllConsultations, updateConsultation} from "../../utils/appwrite/appwriteConfig";

export const getConsultations = () => {
  return getAllConsultations().then((res) => {
    return res;
  });
};


export const updateConsultationData = (id, data) => {
  return updateConsultation().then(id, data).then((res) => {
    return res;
  });
};




