import React from 'react'
import Layout from "../component/Layout Component/Layout"
import { Patientcard } from '../component/Layout Component/Cards.jsx';
import Patientinfotable from '../component/Layout Component/Patientinfotable'

//
import { BiSearchAlt } from "react-icons/bi";


const AdminPatients = () => {
  
  return (
   <Layout>
      <Patientcard/>
       {/* search */}

      <Patientinfotable/>
     

   </Layout>
  )
}

export default AdminPatients