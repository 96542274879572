import { createContext, useState } from "react";
export const DataContext = createContext();

export const DataProvider = ({ children }) => {

  const [allDoctors, setAllDoctors] = useState(null);
  const [doctor, setDoctor] = useState(null);
  const [allPatients, setAllPatients] = useState(null);
  const [patient, setPatient] = useState(null);
  const [schedular, setSchedular] = useState(null);
 

  // Doctor information Context
  const [doctorinformation, setDoctorInformation] = useState({
    firstname: "",
    lastname: "",
    title: "",
    email: "",
    gender: "",
    image: "",
    phonenumber: "",
    submit: "", 
    dateofbirth: "",
    organization: "",
    specialization: "",

    data: {
       
    },
   
  });


 


  //Organization Information Context
  const [orgInformation, setOrgInformation] = useState({
    company_name:"",
    company_address:"",
    company_email:"",
    admin_email:"",
    admin_password:"",
    rcnumber:"",
    submit:"",
    
  });
  
  const [scheduledoc, setSecheduledoc] = useState({
    doctors:[],
    date:"",
    starttime: "",
    endtime: "",
  });
 
  const [adminInfo, setAdminInfo] = useState({
    first_name: "",
    last_name:"",
    email: "",
    designation: "",
  });  

  const data = {
    doctorinformation,
    adminInfo,
    setAdminInfo,
    setDoctorInformation,
    scheduledoc, setSecheduledoc,
    orgInformation, 
    setOrgInformation,
    allDoctors,
    setAllDoctors,
    doctor,
    setDoctor,
    allPatients,
    setAllPatients,
    patient,
    setPatient,
    
   
  };

  return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
};
