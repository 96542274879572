import { createDoctor, createUserAccount, updateDoctor } from "../../utils/appwrite/appwriteConfig";

export const createNewDoctorUserAccount = (email, password, first_name, last_name) => {
  const name = `${first_name} ${last_name}`;
  return createUserAccount(email, name)
};

export const createNewDoctor = ({userID, first_name, last_name, designation, specialization, organization, email}) => {
  return createDoctor({userID, first_name, last_name, designation, specialization, organization, email})
};


export const updateDoctorData = (id,data) => {
  return updateDoctor(id,data).then((res) => {
    return res;
  });
};