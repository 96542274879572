import React, {useState, useContext} from 'react'
import { Link, useLocation } from 'react-router-dom'

const Headings = (props) => {
  const location = useLocation()

  console.log({location})

  return (
    
        <div className="">
          <div className="text-end pr-10 md:pr-20 text-[12px] md:text-[16px] mt-0  md:mt-8 pb-12">
            PatientsID: XCER1003
          </div>

          <div className="flex  text-grayon  mb-12  px-4 md:px-0 text-center md:text-start">
            <Link to='/patientinformation'  className={`text-[12px] md:text-[18px]  pr- md:pr-26  lg:pr-44 ${location.pathname=="/patientinformation"?"activeTextColor":""} `}>Patient Information</Link>
            <Link to='/medicalhistory'  className={`text-[12px] md:text-[18px] pr-6 md:pr-26  lg:pr-44 ${location.pathname=="/medicalhistory"?"activeTextColor":""} `}>Medical History</Link>
            <Link to='/consultation' className={`text-[12px] md:text-[18px] pr-6 md:pr-26  lg:pr-44 ${location.pathname=="/consultation"?"activeTextColor":""} `}>Doctor’s Consultation</Link>
            <Link to='/referral' className={`text-[12px] md:text-[18px] pr-6 md:pr-26  lg:pr-44 ${location.pathname=="/referral"?"activeTextColor":""} `}>Referrals</Link>
          </div>
          

         
        </div>
    
  )
}

export default Headings