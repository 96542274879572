import React from "react";
import Header from "./Header.jsx";
import Sidebar from "./Sidebar.jsx";
import Headings from "./Headings.jsx";

//
import Slidesidebar from "./Slidesidebar.jsx";

const Layout = (props) => {
  return (
    <>
      <Slidesidebar>
        <Header/>
        <div className=" lg:grid grid-cols-5 h-full ">
          <Sidebar />
          <div className="col-span-4 text-start md:block px-0 md:px-12  pt-5">
            {props.showHeading ? (
              <Headings setActivepage={props.setActivepage} />
            ) : (
              <></>
            )}
            <div className="">{props.children}</div>
          </div>
        </div>
      </Slidesidebar>
    </>
  );
};

export default Layout;
