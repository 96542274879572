import { createContext, useState } from "react";

export const showContext = createContext()
const Provider = showContext.Provider

const ShowProvider = (props)=>{
    const [isShown,setIsShown] = useState(false)

    return <Provider value={{isShown,setIsShown} }>
        {props.children}
    </Provider>
}

export default ShowProvider;