import {useState, useContext } from "react";
import { UtilityContext } from "../context/Utility/UtilityContext";
import useNotification from "../hooks/useNotifications";
import { getHealthtips, updateHealthtipData } from "../services/healthtip/healthtip";


const useHealthtips= () => {
  const { setShowLoader } = useContext(UtilityContext);
  const { handleError, handleSuccess } = useNotification();
  const [allHealthtips,setAllHealthtips] = useState([])

  const updateHealthtips= async (id, data) => {
    setShowLoader(true);
    console.log('udpate healthtips',id,data);
    try {
       const updateRes = await updateHealthtipData  (id, data)
       console.log({updateRes})
       if(!updateRes.$id) throw new Error(`healthtips failed to be updated`)
       handleSuccess("Healthtip updated successfully");
       return true
    } catch (error) {
      console.log({error})
      handleError(error);
      return false
    } finally {
      setShowLoader(false);
    }
  };

  const getAllHealhtipsList = async ()=>{
    const healthtips = await getHealthtips()
    console.log({healthtips})
    setAllHealthtips(healthtips.documents)
  }

  return {
    allHealthtips,
    updateHealthtips,
    getAllHealhtipsList 
  };
};

export default useHealthtips;
